.ie-migration-modal {
  .ie-migration-modal-header {
    font-size: 36px;
    color: #ff6a00;
    text-align: left;
    margin: 32px 40px 8px 18px;
    padding-bottom: 0px;
  }
  .ie-migration-modal button {
    margin: 15px 15px 0px 0px;
  }
  .ie-migration-modal-container {
    margin:-12px 18px 34px -2px;
  }
  .ie-migration-modal-link-ul {
    font-size: 19px;
    text-align: left;
    margin-top: 30px;
    padding-left: 40px;
    .ie-migration-browser-link {
      color: #0077c5;
      display: block;
      text-align: left;
      text-decoration: none;
    }
  }
  .ie-migration-sub-modal-container {
    font-size: 19px;
    color: #393a3d;
    text-align: left;
  }
  .margin-bottom-35{
    margin-bottom:35px;
  }
}
