.ie11-banner-container {
  text-align: center;
  background: #ff9331;
  width: 100%;
  padding: 10px 0;

  .ie11-banner-text {
    display: inline;
    font-weight: 600;
    font-size: 22px;
    color: #000;
  }

  button.ie11-banner-btn {
    margin-left: 20px;
    span {
      background-color: #0D333F;
      border-radius: 18px;
      &:hover,
      &:active,
      &:focus,
      &:visited {
        background-color: #0D333F;
        outline-color: #0D333F;
      }
    }
  }
  button#btnIe11Migration {
    border-radius: 5px;
    background-color: #0D333F;
  }
}
