@import "../stylesheets/button.scss";

.sign-in-modal-image {
  height: auto !important;
  width: auto !important;
  margin: 0 0 0 2rem !important;
  clear: none !important;
  display: block;
  padding-bottom: 20px;
}

.seperator {
  border-left: solid 1px #ddd;
  height: 200px;
  width: 1px;
  margin-top: 120px;
  float: left;
  min-height: 1px;
}

.sign-in-page [class^='StyledModalDialog__InnerContainer'] {
  margin: 0px;
  padding: 0px;
}

.p2-small {
  font-size: 15px;
  font-weight: 400;
}

.sign-page-txt-small {
  font-size: 19px;
  font-weight: 500;
}

.p2-smaller {
  font-size: 14px;
  font-weight: 300;
}

.sign-page-txt-smaller {
  font-size: 16px;
  font-weight: 400;
}

.new-user-section {
  text-align: left;
  margin-top: 40px;
}

// Keep the media query at the end only
@media (min-width: 0px) {
  .sign-in-modal {
    z-index: 20000 !important;
    //display: block;
    flex-basis: 0;
    flex-grow: 1;
    min-width: calc(100%);
    max-width: 100vh;
    min-height: 1px;
  }

  .sign-in-modal-ie {
    height: 575px;
    width: 695px !important;
    min-height: 100%;
  }
}

@media (min-width: 480px) {
  .sign-in-modal {
    z-index: 20000 !important;
    //display: block;
    flex-basis: 0;
    flex-grow: 1;
    min-width: calc(100%);
    max-width: 100vh;
    min-height: 1px;
  }
}

@media (min-width: 769px) {
  .sign-in-modal {
    z-index: 20000 !important;
    //display: block;
    flex-basis: 0;
    flex-grow: 1;
    min-width: calc(100%);
    width: 80vh;
    max-width: 100vh;
    min-height: 1px;
  }
}

@media (min-width: 1030px) {
  .sign-in-modal {
    z-index: 20000 !important;
    //display: block;
    flex-basis: 0;
    flex-grow: 1;
    min-width: calc(100%);
    width: 100vh;
    max-width: 731px;
    min-height: 1px;
  }
}

.sign-in-modal {
  .StyledModalDialog__Wrapper-cn89yw-0.dGWdLZ {
    overflow: auto;
  }

  .StyledModalDialog__InnerContainer-cn89yw-8.hCvTLR {
    padding-top: 0px;
  }

  .ius .ius-header-container.ius-header-small-padding {
    padding-bottom: 18px;
  }

  .ius .ius-conditional-hr {
    margin: 7px 0;
    padding: 10px;
  }

  .ius .ius-checkbox-container {
    padding-bottom: 20px;
  }

  .ius-sign-in-widget .ius-captcha-tos-container {
    padding: 16px 0px 17px 30px;
    text-align: left;
  }

  div#ius-captcha-tos-container-display {
    width: 220px;
  }

  .new-user-section ul {
    display: none;
  }

  .ius {
    .ius-section {
      font-family: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
    }

    .ius-header {
      font-size: 28px;
      font-weight: 600;
    }

    .ius-label {
      font-family: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
      font-size: 19px;
      font-weight: 500;
    }

    .ius-captcha-tos-container {
      margin: 10px -30px -30px -30px;
    }

    .ius-display-inline-block {
      font-size: 13px;
      font-weight: 400;
    }

    a {
      font-size: 13px;
      font-weight: 600;

      span {
        visibility: hidden;
        display: none;
      }
    }

    #ius-browser-warning-container {
      display: none;
    }

    #ius-captcha-terms,
    #ius-captcha-privacy-policy {
      font-size: 11px;
    }

    .ius-sub-header {
      padding-top: 0px;
    }

    #ius-error-password {
      min-height: 15px;
    }

    #ius-error-userid {
      min-height: 10px;
    }

    #ius-captcha-terms-of-use {
      font-family: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
      font-size: 11px;
    }

    .ius-btn {
      border-radius: 25px;
      padding-bottom: 2px
    }

    #ius-sign-in-submit-btn-text {
      font-size: 16px;
      position: relative;
      top: 5px;
      font-weight: 400;
      font-family: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
    }

    #ius-sign-in-submit-img {
      position: relative;
      bottom: 1px;
    }
  }

  #idsModalHeader1 {
    padding-bottom: 0px;
  }

  #idsModalHeader2 {
    padding-bottom: 0px;
  }

  .sign-in-iebox {
    text-align: justify;
    margin: 0px -20px -22px;

    .sign-in-headerbox {
      background-color: #F3F2EF;
      padding: 12px 40px 41px;
      margin: 0px;
      font-family: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
      color: #0D333F;
      line-height: 1.6;

      .header {
        font-size: 26px;
        font-weight: 700;
      }

      .box {
        font-size: 19px;
        display: block;
        color: #0D333F;
        margin-bottom: 28px;

        .small {
          font-weight: 600;
          padding-bottom: 20px;
        }
      }

      .sign-in-link-desc {
        margin-bottom: 23px;
      }

      .link-color {
        cursor: pointer;
        text-decoration: none;
        font-size: 19px;
        color: #0077C5;
        display: block;
        line-height: 30px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .sign-in-modal {

    #ius-userid,
    #ius-password {
      width: 210px;
    }

    #ius-label-userid,
    #ius-label-password {
      width: 255px;
    }
  }
}

@media (max-width: 1200px) {
  .sign-in-modal {

    #ius-userid,
    #ius-password {
      width: 163px;
    }

    #ius-label-userid,
    #ius-label-password {
      width: 163px;
    }
  }
}

button.create-acc-btn {
  color: black;
  font-size: 16px;

  span {
    span {
      color: #000000;
    }
  }
}

h4.sign-in-txt {
  font-size: 28px;
  font-weight: 600;
}

.hosted-sign-in {
  color: #393a3d;
  text-align: left;
  padding-top: 40px;

  h1 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}

.hosted-sign-in-bottom-border {
  border-bottom: 1px solid #babec5;
}

.hosted-sign-in-action {
  margin-bottom: 30px;
  .sign-in-tartiary-link {
    color: #0077c5;
    text-decoration: none;
    font-weight: 600;
  }
  .sign-in-passive-btn {
    border-radius: 4px;
    $button-min-height: 40px;
    @include secondary-dark-blue-button($button-min-height);
  }
  
  .sign-in-green-btn {
    $button-min-height: 40px;
    border-radius: 4px;
    @include primary-green-button($button-min-height);
  }
}


.display-flex{
  display: flex;
}
.justify-content-end{
  justify-content: end;
}