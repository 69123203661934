.price-text {
  font-size: 17px;
}
.cart-line-item:nth-child(1) {
  padding-top: 0;
  border: 0;
}
.cart-line-item {
  padding: 20px 0 15px;
  min-height: 74px;
  border-top: 1px solid #b3b4b6;
}
.cart-line-item-padding {
  margin: 0 60px 0 0 !important;
}
.value-add-title {
  font-size: 14px;
  font-weight: 500;
}
.value-add-container {
  border-top: 1px solid #d4d7dc;
  padding-top: 20px;
  margin-top: 20px;
}
.custom-height {
  height: 30px;
}
.cart-item-desc {
  margin-top: 10px;
  font-size: 13px;
}
.strikePrice {
  text-decoration: line-through;
  margin-right: 0px !important;
}
.discount-price {
  font-size: 20px;
  font-weight: 500;
  color: #2ca01c;
}
.discount-container {
  margin-top: 36px;
}
.discount-col-margin {
  margin: 10px 0 35px 0;
}
.discount-note-text {
  font-size: 13px;
  font-weight: 400;
}
.discount-btn-col {
  text-align: right;
}
.discount-input-field {
  width: 100% !important;
}
.applied-discount {
  font-size: 13px;
  font-weight: 600;
  margin: 20px 0px;
}
.remove-discount {
  color: #0077c5;
  cursor: pointer;
  font-weight: 500;
}
.xsell-container {
  margin-top: 80px;
}
.cart-xsell-product-header {
  font-size: 26px;
  color: #000;
  font-weight: 500;
  padding: 0 0 15px;
}
.cart-sell-products-container {
  padding: 60px 0 30px 0;
  border-top: 1px solid #b3b4b6;
}
.cart-xsell-first-col-image {
  width: 100%;
  max-width: 200px;
}
.cart-xsell-image {
  width: 100%;
  max-width: 150px;
}
.cart-xsell-product-title {
  font-size: 17.5px;
  font-weight: 500;
  margin-bottom: 20px;
}
.cart-xsell-desc-container ul {
  font-size: 15px;
  padding-inline-start: 17px;
  margin-bottom: 20px;
}
.cart-xsell-desc-container ul li {
  font-size: 15px;
  font-weight: 400;
  padding-left: 5px;
}
.cart-xsell-desc-container ul li > p {
  font-size: 15px;
}
.cart-item-remove {
  font-size: 18px;
}
.remove-confirmation-btn {
  margin: 50px 0 25px 0;
}
.remove-confirmation-btn button {
  margin: 10px 10px 0 0px;
}
.note-border {
  border-top: 1px solid #b3b4b6;
}
.previewIframe {
  width: 935px;
  height: 580px;
  border: none;
}
.xsell-tootltip-width {
  width: 180px;
}
.xsell-info-svg {
  width: 18px;
  height: 18px;
}
.item-container-top {
  margin-top: 85px;
}
.line-item-order-1 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 1;
  order: 1;
}
.line-item-order-2 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 2;
  order: 2;
}
.line-item-order-3 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 3;
  order: 3;
}
.line-item-order-4 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 4;
  order: 4;
}
.line-item-order-5 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 5;
  order: 5;
}
.line-item-order-6 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 6;
  order: 6;
}
.line-item-order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}
.line-item-order-8 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 8;
  order: 8;
}
.btn-grp-margin {
  margin-top: 100px;
}
.discount-input-field > span {
  position: absolute;
}
.reorder-link {
  font-size: 13px;
  font-weight: 500;
  color: #0077c5;
  text-decoration: none;
}
.order-desc-text {
  font-size: 13px;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .margin-top {
    margin-top: 20px;
  }
  .discount-btn-col {
    text-align: left;
    margin-top: 20px;
  }
  .line-item-order-2 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 8;
    order: 8;
  }
  .line-item-order-8 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 2;
    order: 2;
  }
}

@media screen and (max-width: 481px) {
  .cart-preview-image {
    max-width: 100px;
  }
  .quantity-dropdown {
    width: 100% !important;

    .idsDropdownTextField {
      width: 100% !important;
    }
  }
  .cart-line-item-padding {
    margin: 0px;
  }
  .discount-btn {
    width: 100% !important;
  }
  .xsell-image-container {
    padding: 35px 0;
  }
  .xsell-image-padding {
    padding-bottom: 35px;
  }
  .cart-xsell-first-col-image {
    width: 100%;
    max-width: 150px;
  }
  .cart-sell-products-container {
    padding-top: 30px;
    border-top: 0;
  }
  .xsell-first-col-header {
    padding: 30px 0 20px 0;
  }
  .xsell-first-col {
    padding-bottom: 50px;
  }
  .xsell-margin-top {
    background-color: #f4f5f8;
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 40px;
  }
  .discount-container {
    margin: 50px 0 10px;
  }
  .item-container-top {
    margin-top: 70px;
  }
  .margin-top-adjust {
    margin-top: -50px;
  }
  .order-7-col-1 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-7-col-2 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-7-col-3 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-7-col-4 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-7-col-5 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-7-col-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-7-col-7 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .mob-xsell-background {
    background-color: #f4f5f8;
    padding: 0 20px;
    margin-left: -20px;
  }
  .btn-grp-margin {
    margin-top: 40px;
  }
}

@media (min-width: 480px) {
  .cart-preview-image {
    max-width: 150px;
    width: 100%;
  }
  .desktop-margin {
    margin-bottom: -35px;
  }
  .margin-right {
    margin-right: 20px !important;
  }
  .xsell-first-col {
    background-color: #f4f5f8;
    height: 100%;
    margin-right: 30px;
  }
  .xsell-first-col-header {
    margin: 0;
    border: 0;
    background-color: #ffffff;
  }
  .xsell-first-col-padding {
    padding: 40px 10px 40px 40px;
  }
  .xsell-image-container {
    padding: 35px 0px;
    background: #ffffff;
    text-align: center;
    margin-bottom: 20px;
  }
  .xsell-margin-top {
    margin-top: 80px;
  }
  .order-7-col-1 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-7-col-2 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-7-col-3 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-7-col-4 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-7-col-5 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-7-col-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-7-col-7 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 7;
    order: 7;
  }
}
