// Keep the media query at the end only
.deposit-slip-image-container {
  position: relative;
  text-align: center;
}

.mds-image-container {
  position: relative;
  text-align: center;
}

@media (min-width: 0) {
  .deposit-slip-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 39px;
      left: 25px;
      font-size: 7px;
      line-height: 7px;
    }

    .image-summary-text {
      position: absolute;
      top: 70%;
      left: 27px;
      font-size: 7px;
    }
    .image-micr {
      position: absolute;
      top: 60%;
      left: 38%;
      font-size: 7px;

      img {
        width: 7px;
        height: 9px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 52%;
      left: 8%;
      line-height: 7px;
      font-size: 7px;
    }
  }

  .mds-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 30px;
      left: 66%;
      font-size: 5px;
      line-height: 5px;
    }
    .image-micr {
      position: absolute;
      top: 89%;
      left: 7%;
      font-size: 11px;

      img {
        width: 6px;
        height: 9px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 48%;
      left: 10%;
      font-size: 8px;
      line-height: 9px;
    }
  }
}

@media (min-width: 480px) {
  .deposit-slip-image-container {
    .image-company-info {
      position: absolute;
      top: 27px;
      left: 6px;
      font-size: 8px;
    }

    .image-summary-text {
      position: absolute;
      top: 35%;
      left: 18px;
      font-size: 8px;
    }

    .image-micr {
      position: absolute;
      top: 29%;
      left: 39%;
      font-size: 11px;

      img {
        width: 8px;
        height: 9px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 24%;
      left: 4%;
      font-size: 7px;
    }
  }

  .deposit-slip-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 59px;
      left: 15%;
      font-size: 13px;
      line-height: 14px;
    }
    .image-summary-text {
      position: absolute;
      top: 35%;
      left: 15%;
      font-size: 11px;
    }
    .image-micr {
      position: absolute;
      top: 29%;
      left: 39%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 25%;
      left: 15%;
      line-height: 12px;
      font-size: 11px;
    }
  }

  // Manual Deposit slips - DEP=01
  .mds-image-container {
    .image-company-info {
      position: absolute;
      top: 18px;
      left: 69%;
      font-size: 6px;
      line-height: 6px;
    }
    .image-micr {
      position: absolute;
      top: 53%;
      left: 4%;
      font-size: 11px;

      img {
        width: 7px;
        height: 10px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 26%;
      left: 10%;
      font-size: 9px;
    }
  }

  .mds-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 44px;
      left: 66%;
      font-size: 9px;
      line-height: 8px;
    }
    .image-micr {
      position: absolute;
      top: 53%;
      left: 7%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 27%;
      left: 10%;
      font-size: 10px;
      line-height: 11px;
    }
  }
}

@media (min-width: 769px) {
  // Printable Deposit slips - DEP=02
  .deposit-slip-image-container {
    .image-company-info {
      position: absolute;
      top: 37px;
      left: 3%;
      font-size: 9px;
    }
    .image-summary-text {
      position: absolute;
      top: 35%;
      left: 18px;
      font-size: 11px;
    }
    .image-micr {
      position: absolute;
      top: 29%;
      left: 39%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 24%;
      left: 3%;
      font-size: 9px;
    }
  }

  .deposit-slip-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 59px;
      left: 13.5%;
      font-size: 10px;
      line-height: 14px;
    }
    .image-summary-text {
      position: absolute;
      top: 35%;
      left: 15%;
      font-size: 11px;
    }
    .image-micr {
      position: absolute;
      top: 29%;
      left: 39%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 25%;
      left: 13.5%;
      line-height: 12px;
      font-size: 10px;
    }
  }

  // Manual Deposit slips - DEP=01
  .mds-image-container {
    .image-company-info {
      position: absolute;
      top: 26px;
      left: 68%;
      font-size: 8px;
      line-height: 8px;
    }
    .image-micr {
      position: absolute;
      top: 53%;
      left: 3%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 26%;
      left: 10%;
      font-size: 9px;
    }
  }

  .mds-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 44px;
      left: 66%;
      font-size: 9px;
      line-height: 8px;
    }
    .image-micr {
      position: absolute;
      top: 53%;
      left: 7%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 27%;
      left: 10%;
      font-size: 10px;
      line-height: 11px;
    }
  }
}
