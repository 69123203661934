.headerInfo {
  li a {
    font-size: 1.5em;
  }

  .bh-promo {
    transition: all 0.6s linear 0.3s;
    height: 233px;
    background: linear-gradient(-46deg, #00c1bf 0, #53b700 100%);
    position: relative;
    overflow: hidden;
    display: none;
    width: 100%;
  }
  .beam-logo {
    background-image: url('../assets/images/IM/Banners/ic-promo-banner-beams-expanded-desktop.svg');
    width: 471px;
    height: 230px;
    position: absolute;
    top: 0;
    right: 10%;
  }
  .offer-bar {
    text-align: center;
    margin-top: 60px;
  }
  .banner-message {
    font-weight: 500;
    color: #ffffff;
    font-size: 2em;
    margin-top: 20px;
  }
  .condition-data {
    font-weight: 500;
    color: #ffffff;
    font-size: 1.5em;
  }
  .collapsed {
    height: 60px;
  }
  .collapsed-image {
    height: 60px;
  }
  .offer-bar-collapsed {
    margin-top: 0px;
  }
  .offer-bar-collapsed > p {
    display: inline-block;
    margin-left: 20px;
    margin-top: 16px;
  }
  ul.resouce-center {
    padding: 0px;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 10px;
    color: #393a3d;
  }
  .im-info {
    margin-left: 34px;
    margin-right: 34px;
    border-bottom: 1px solid #e0e0e0;
    height: 60px;
  }
  .flex-container {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid #e0e0e0;
    margin-left: 34px;
    margin-right: 34px;
  }

  .flex-container > div {
    text-align: center;
    margin: 10px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .flex-container > div:first-child {
    margin-left: 0px;
  }
  .header-logo {
    height: 27px;
    background-image: url('../assets/images/IM/Header/ic-quickbooks-logo-1x.png');
    background-repeat: no-repeat;
    width: 131px;
  }
  .im-logo {
    display: inline-block;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .icon-dropdown {
    background-image: url('../assets/images/IM/Header/ic-arrow-down-1x.png');
    background-repeat: no-repeat;
    display: inline-block;
    width: 14px;
    height: 8px;
    margin-left: 8px;
  }
  .bullet-spacing {
    padding: 0 8px 0 8px;
    display: inline;
  }
  label {
    margin-bottom: 5px;
    font-weight: 700;
  }
  .resouce-center {
    float: right;
  }

  .icon {
    display: none;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 1.4em;
    border: none;
    padding: 24px 0 16px 0;
    background-color: transparent;
    font-weight: 500;
    color: #6b6c72;
    margin: 0;
    margin-right: 38px;
    cursor: pointer;
  }
  .im-name-head .dropbtn {
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    margin-right: 40px;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    text-decoration: none;
    display: block;
    text-align: left;
    margin: 0px;
    color: #505050;
    font-size: 14px;
    padding: 12px 0 12px 20px;
    font-weight: 400;
    width: 220px;
  }

  .topnav a:hover,
  .dropdown:hover .dropbtn {
    border-bottom: 4px solid #2ca01c;
    border-radius: 2px;
  }

  .dropdown-content a:hover {
    background-color: #eceef1;
    color: #000000;
    border: none;
    margin: 0;
    font-weight: 500;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content.display-dropdown{
    display:block;
  }
  .dropdown-content.hide-dropdown{
    display:none;
  }

.display-dropdown-account{
    display:block;
  }
  .hide-dropdown-account{
    display:none;
  }
  
  .dropdown:hover .icon-dropdown, .display-up-arrow {
    background-image: url('../assets/images/IM/Header/ic-arrow-up-1x.png');
    background-repeat: no-repeat;
    display: inline-block;
    width: 14px;
    height: 8px;
    margin-left: 8px;
  }
  .margin-data {
    margin: 0;
    margin-right: 40px;
  }
  .margin-data:hover {
    border-bottom: 4px solid #2ca01c;
    padding-bottom: 20px;
  }
  .search-type {
    float: right;
  }
  ul li {
    display: inline-block;
  }
  .search-form {
    position: relative;
  }
  .search-query {
    background: transparent;
    border: 1px solid #babec5;
    height: 27px;
    border-radius: 4px;
    color: #505050;
    text-align: left;
    text-indent: 15px;
    position: relative;
    width: 96%;
  }
  .customer-care {
    display: none;
  }
  .add-on {
    background-color: transparent;
    background-image: url('../assets/images/IM/Footer/ic-footer-search.svg');
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 5px;
    top: 8px;
    cursor: pointer;
  }

  .display-data {
    margin-top: 17px;
  }
  .border-invisible {
    border: none;
  }

  .im-name {
    display: inline-block;
    margin-left: 28px;
    margin-top: 20px;
    vertical-align: top;
    font-weight: 600;
    color: #393a3d;
    font-size: 20px;
  }
  .im-name-head {
    display: none;
  }
  .dropbtn:hover .icon-dropdown {
    background-image: url('../assets/images/IM/Header/ic-arrow-up-1x.png');
    background-repeat: no-repeat;
    display: inline-block;
    width: 14px;
    height: 8px;
    margin-left: 8px;
  }

  @media (min-width: 1200px) {
    .container {
      width: 1200px;
      margin: 0px auto;
      height: 60px;
    }
  }

  @media screen and (max-width: 1200px) {
    .dropdown .dropbtn {
      margin-right: 25px;
    }
  }
  @media screen and (max-width: 1110px) {
    .dropdown .dropbtn {
      margin-right: 14px;
    }
  }
  @media screen and (max-width: 1028px) {
    .margin-data {
      margin-top: 0px;
    }
    .im-name {
      display: none;
    }
    .im-name-head {
      display: inline-block;
      margin-top: 24px;
      margin-bottom: 16px;
      font-size: 1.5em;
    }
    .im-name-head > button {
      font-weight: 600;
      color: #393a3d;
      font-size: 16px;
      padding: 0;
    }
    .add-on {
      top: -12px;
    }
    .search-query {
      display: none;
      background: white;
      width: 100%;
    }
    .headerInfo .dropdown .dropbtn {
      padding: 16px 0 16px 0;
    }

    .topnav {
      display: none;
      background: white;
      position: absolute;
      width: 70%;
      border: 1px solid #e0e0e0;
    }
    .topnav .dropdown {
      float: none;
      border-bottom: 1px solid #d4d7dc;
      padding: 0 20px 0 20px;
    }
    .dropdown-content {
      height: auto;
      position: static;
      margin: 0 -20px 0 -20px;
      background: #eceef1;
    }
    .dropdown-content a {
      padding: 0 0 20px 20px;
    }
    .dropdown-content a:first-child {
      padding-top: 20px;
    }
    .topnav a:hover,
    .dropdown:hover .dropbtn {
      border-bottom: none;
    }
    .display-status {
      display: block;
      z-index: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .offer-bar-collapsed {
      text-align: left;
    }
    .collapsed {
      height: 100px;
    }
    .offer-bar-collapsed > p {
      display: block;
      height: px;
    }
    .beam-logo {
      background-image: url('../assets/images/IM/Banners/ic-promo-banner-dots-tablet.svg');
      width: 180px;
      height: 36px;
      position: absolute;
      top: 50%;
      right: 2%;
    }
  }

  @media (min-width: 768px) {
  }

  @media screen and (max-width: 481px) {
    .icon {
      display: inline-block;
    }
    .im-logo {
      vertical-align: middle;
      margin-left: 16px;
    }
    .beam-logo {
      background: none;
    }
    .topnav {
      width: 100%;
      margin: 0 -20px 0 -20px;
    }
    .customer-care {
      display: inline-block;
    }
    .display-data {
      display: none;
    }
    .im-icon.hamburger div {
      width: 25px;
      height: 4px;
      background: #fff;
      border-radius: 4px;
      transition: 0.4s;
    }
    .hamburger {
      width: 20px;
      height: 17px;
      transition: 0.4s;
      background-image: url('../assets/images/IM/Header/ic-mobile-menu.svg');
      background-repeat: no-repeat;
      vertical-align: middle;
      display: inline-block;
      z-index: 10000;
    }
    .hamburger-to-cross {
      background-image: url('../assets/images/IM/Header/ic-mobile-menu-close.svg');
      z-index: 999;
    }
    .im-info {
      margin: 0px;
      padding: 0 20px 0 20px;
      border-bottom: 1px solid #e0e0e0;
    }
    ul.resouce-center li {
      display: block;
    }

    .resouce-center {
      height: calc(100vh);
      width: 0;
      position: absolute;
      z-index: 1;
      left: 0;
      background-color: #ffffff;
      overflow-x: hidden;
      transition: 0.5s;
      margin-top: 2px;
    }

    .resouce-center a {
      padding: 8px 8px 8px 20px;
      text-decoration: none;
      font-size: 16px;
      color: #393a3d;
      display: block;
      transition: 0.3s;
      border-bottom: 1px solid #e0e0e0;
      margin-right: -20px;
    }

    .resouce-center a:hover {
      color: #eceef1;
    }
    .resouce-center-drawer {
      width: 100%;
    }
    #IMFooterSearchForm {
      display: none;
    }
  }
}
.sticky-header {
  top: 0 !important;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  width: 100%;
  position: fixed;
  position: sticky;
  z-index: 998;
  background: #ffffff;
}
.intl-tel-input input[type='tel'] {
  width: 60%;
}
