//Custamization Options

.custom-subHeader {
  font-size: 16px;
  color: #393a3d;
  margin-bottom: 33px;
  font-weight: 400;
}
.saved-item-container {
  margin: 16px 0px;
  .delete_saved_work {
    background-color: #000;
    span {
      color: #fff;
    }
  }
}
.save-work-title {
  font-size: 19px;
  margin-bottom: 15px;
  color: #393a3d;
}
/* .saved-work-details p, .found-logo-text {
    font-size: 14px;
    color: #393A3D;
    padding:0px 15px;
} */
.saved-logo-detail {
  margin: 10px 0 0 10px;
}
.no-logo-text,
.no-work-text {
  font-size: 15px;
  line-height: 22px;
  color: #393a3d;
}
.background-mist {
  background-color: #f4f5f8;
  color: #393a3d;
  font-size: 14px;
}
.saved-logo-list {
  margin: 10px 0px 37px;
}
.saved-logo-content {
  text-align: center;
  padding: 20px 0px 0px;
  margin-bottom: 20px;
  margin-right: 2.8%;
  width: 48.52%;
  display: inline-block;
}

.saved-logo-content:nth-child(even) {
  margin-right: 0px;
}
.saved-logo-content img {
  max-width: 100%;
  width: auto;
  height: 100px;
  vertical-align: middle;
  border: 0;
  margin: 20px 0px;
}
.saved-logo-details {
  word-wrap: break-word;
  padding: 20px 5px;
}

.saved-logo-details span {
  margin-top: 5px;
  display: block;
}

.saved-logo-inner {
  margin-bottom: 20px;
  padding: 20px 20px 0px 20px;
}

.logo-img-background {
  background-color: #ffffff;
}
.saved-work-confirmation-btn {
  margin: 25px 0;
  text-align: right;

  button {
    margin: 0px 5px;
  }
}
.upload-drawer {
  width: 500px !important;
  margin-top: 28px;
}

.upload-drawer-width{
    margin:40px;
}
.upload-logo-container {
  position: relative;
  text-align: left;
}
.upload-modal {
  z-index: 20002;
  position: fixed;
  right: 0;
  top: 0;
}
.upload-modal-header {
  color: #393a3d;
  padding-bottom: 3px;
  margin: 0 0 24px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  text-align: left;
}
.upload-modal-header-oh{
    margin: 0 0 28px;
}
.upload-list {
  margin: 0px;
  padding-left: 20px;
    li{
        margin-bottom:8px;
    }
    li:last-child{
        margin-bottom:0;
        line-height: 25px;
    }
}
.upload-list-oh{
    li{
        margin-bottom: 18px;
    }
    li:last-child{
        margin-bottom:0;
        line-height: 20px;
    }
}
.file-name-label {
  font-size: 12px;
  color: #0077c5;
  margin-left: 15px;
  word-break: break-all;
  line-height: 30px;
}
.upload-tips-container {
  margin: 60px -40px;
  background-color: #eceef1;
}
.upload-tips-subcontainer {
  margin: 0px 40px;
}
.close-upload {
  float: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  cursor: pointer;
}
button.upload-image-button{
    margin-top: 25px;
}
.upload-modal-subHeader {
  font-size: 16px;
  font-weight: 400;
  color: #393a3d;
  text-align: left;
}

.captcha-container {
  margin-bottom: 25px;
}

.upload-file {
  font-size: 13px;
  color: #3962bd;
  text-align: left;
}

.upload-button {
  background: #2ca01c;
  color: #fff;
  border: 2px solid #2ca01c;
}

.upload-tips-header {
  font-size: 24px;
  color: #393a3d;
  padding: 16px 0;
  font-weight: 700;
  text-align: left;
}

.upload-tips {
  padding-bottom: 20px;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #393a3d;
  text-align: left;
  margin:0px;
    li{
        margin-bottom: 16px;
    }
    li:last-child{
        margin-bottom:0
    }
}

.err-msg-oh{
  margin:18px 0;
  font-weight:600px;
  span{
    font-size: 16px;
  }
}
.err-msg{
  span{
    font-size: 16px;
  }
  margin: 18px 0 14px;
}
.upload-tAndc {
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 400;
  color: #393a3d;
  text-align: left;
}

.upload-link {
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  color: #0077c5;
}

.delete-logo {
  background-color: #393a3d !important;
}

.delete-logo span {
  color: #ffffff !important;
}

.saved-work-details p {
  font-size: 14px;
  color: #393a3d;
  padding-left: 10px;
}

.saved-work-details div > img {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 80px;
}

.col-logo-img {
  width: 17%;
}

.col-logo-details {
  width: 83%;
}

.col-saved-data {
  width: 34%;
}

.col-btn-grp {
  width: 66%;
  text-align: end;
}

.col-btn-grp button {
  width: 166px;
}

.custom-continue-btn {
  margin: 0 0 0 10px;
}

.col-btn-grp button:nth-child(even) {
  margin-left: 10px;
}

.add-logo-btn {
  margin: 20px 0 40px 0;
}

@media screen and (max-width: 1065px) {
  .col-saved-data {
    width: 41%;
  }

  .col-btn-grp {
    width: 59%;
    text-align: right;
  }

  .col-btn-grp button:nth-child(even) {
    margin: 10px 0 0 0px;
  }
}

@media screen and (max-width: 840px) {
  .col-saved-data {
    width: 55%;
  }

  .col-btn-grp {
    width: 45%;
    text-align: end;
  }
}

@media screen and (max-width: 768px) {
  .saved-logo-content {
    padding-top: 0px;
  }
  .saved-work-details p {
    padding: 0;
    white-space: nowrap;
  }
  .customization-options-container {
    margin-bottom: -58px;
  }
  .col-btn-grp {
    width: 45%;
    text-align: end;
    margin-top: 6px;
  }
  .save-data-container {
    display: inline-block;
    margin-left: 20px;
  }
  .col-logo-img {
    width: 25%;
  }
  .col-logo-details {
    width: 75%;
  }
  .col-btn-grp button {
    margin: 10px 0px;
  }
}

@media screen and (max-width: 481px) {
  /*  .found-logo-text{
        padding:0px;
        margin-top:20px;
    } */
  .saved-work-description {
    margin: 10px 0;
  }
  .saved-work-details p {
    padding: 0;
  }
  .saved-work-confirmation-btn {
    text-align: center;
  }
  .upload-drawer {
    width: 90% !important;
  }
  .no-logo-text {
    margin: 20px 0;
  }
  .saved-logo-content {
    padding-top: 20px;
    width: 100%;
  }
  .custom-subHeader {
    font-size: 14px;
  }
  .col-logo-img {
    width: 100%;
  }
  .col-logo-details {
    width: 100%;
  }
  .save-data-container {
    display: inline-block;
    margin: 10px 0px;
  }
  .add-logo-btn {
    margin: 20px 0 20px 0;
  }
  .customization-options-container {
    margin-bottom: -2px;
  }
}
