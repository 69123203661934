$font-size: 17px, 19px, 20px, 23px, 35px, 36px, 40px;
@each $size in $font-size {
  .font-size-#{$size} {
    font-size: $size ;
  }
}

$boxPadding: 0px, 1px, 5px, 8px, 10px, 12px, 15px, 17px, 20px, 25px, 26px, 30px, 35px, 36px, 39px, 40px, 45px, 50px, 54px, 55px, 60px, 61px, 65px, 80px, 210px, 235px;

@each $padding in $boxPadding {
  .padding-right-#{$padding} {  
    padding-right: $padding ;
  }
  .padding-left-#{$padding}{
    padding-left: $padding ;
  }
  .padding-bottom-#{$padding}{
    padding-bottom: $padding ;
  }
  .padding-top-#{$padding}{
    padding-top: $padding ;
  }
}
$boxMargin: -36px,-30px,-10px, -5px, 0px, 5px, 10px, 12px, 15px, 20px, 26px, 30px, 33px, 36px, 39px, 40px, 50px, 54px, 55px, 56px, 60px, 61px, 65px, 68px, 70px, 80px;

@each $margin in $boxMargin {
  .margin-right-#{$margin} {
    margin-right: $margin ;
  }
  .margin-left-#{$margin}{
    margin-left: $margin ;
  }
  .margin-bottom-#{$margin}{
    margin-bottom: $margin ;
  }
  .margin-top-#{$margin}{
    margin-top: $margin ;
  }
}

.line-height-normal {
  line-height: normal;
}
.margin-main-checkout-section {
  margin: 60px 34px;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.text-align {
  text-align: right;
}
.product-title {
  font-size: 17.5px;
  font-weight: 500;
}
.justify-content-end {
  -webkit-box-pack: end !important;
  -webkit-justify-content: flex-end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  text-align: right;
}
.cart-edit-links {
  margin: 10px 0 20px 0;
  font-size: 13px;
  color: #d4d7dc;
}
.cart-edit-links button {
  color: #0077c5;
  cursor: pointer;
  font-weight: 500;
  border: none;
  background: none;
}
.checkout-container h1 {
  color: #393a3d;
}
.align-self-center-btns {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.checkout-lhs-container {
  margin-top: 0px;
}
.checkout-rhs-container {
  margin-top: 43px;
}
.margin-step-progress-section {
  padding: 33px 43px 40px 43px;
}
/* FSH checkout banner */
.fsh-home-banner-rectangle {
  height: 175px;
  width: 175px;
  background-color: #c9007a;
}
.fsh-home-banner-triangle {
  width: 0px;
  height: 0px;
  border: 15px solid;
  border-top-width: 0px;
  border-left-color: #c9007a;
  border-right-color: #c9007a;
  border-bottom-color: transparent;
  border-left-width: 87.5px;
  border-right-width: 87.5px;
}
.fsh-checks-banner-rectangle {
  height: 41px;
  padding: 0 9px;
  background-color: #c9007a;
  color: #ffffff;
  text-align: center;
}
.fsh-checks-banner-border {
  height: 20px;
  background-color: transparent;
  background-image: linear-gradient(155deg, #c9007a 50%, transparent 50%),
    linear-gradient(-155deg, #c9007a 50%, transparent 50%);
  background-size: 18px 18px;
  background-repeat: round;
}

.fsh-checkout-banner-rectangle {
  margin: 0 auto;
  width: fit-content;
  width: -moz-fit-content;
  background: linear-gradient(83deg, #0077c5, #34bfff 113%);
  padding: 5px 10px !important;
}

.fsh-home-banner-rectangle h1.banner-txt {
  font-size: 37px;
  color: #ffffff;
  letter-spacing: 0px;
  padding: 25px 10px 12px;
  margin: 0px;
  font-family: AvenirNextLTPro-Demi, 'Helvetica Neue', sans-serif !important;
  padding-right: 10px;
  padding-left: 10px;
  line-height: 30px;
  font-weight: 300;
}

.fsh-home-banner-rectangle h1 > span {
  font-size: 19px;
  vertical-align: super;
  font-family: AvenirNextLTPro-Regular, 'Helvetica Neue', sans-serif !important;
}

.fsh-home-banner-rectangle > span {
  color: #ffffff;
  padding: 10px;
  display: block;
  line-height: 22px;
  font-size: 19px;
  font-family: AvenirNextLTPro-Medium, 'Helvetica Neue', sans-serif !important;
}

#fsh-checks-banner-container {
  position: absolute;
}

.fsh-checks-banner-rectangle span.banner-txt {
  font-size: 19px;
  padding-top: 7px;
  display: inline-block;
  font-family: AvenirNextLTPro-Demi, 'Helvetica Neue', sans-serif !important;
}

.fsh-checks-banner-rectangle span.banner-txt > span {
  font-size: 13px;
  vertical-align: super;
  line-height: 27px;
  font-family: AvenirNextLTPro-Regular, 'Helvetica Neue', sans-serif !important;
}

.fsh-checks-banner-rectangle span.banner-txt-lite {
  font-size: 15px;
}

.fsh-checkout-banner-container {
  width:200px;
  margin: 20px auto;
  color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  line-height: 30px;
}

.fsh-checkout-banner-rectangle span.banner-txt {
  font-size: 30px;
  font-weight: 500;
}
.checkout-container .fsh-checkout-banner-rectangle span.fsh-banner-txt>span.fsh-txt{
  font-size: 30px;
  vertical-align: baseline;
}
.checkout-container .fsh-checkout-banner-rectangle span.fsh-banner-txt-lite{
  font-size: 17px;
}
.fsh-checkout-banner-rectangle span.fsh-banner-txt-lite span.promo-date{
  display:block;
}
.all-checks-page .fsh-checkout-banner-rectangle span.fsh-banner-txt-lite span.promo-date{
  display:inline;
}
.fsh-checkout-banner-rectangle span.fsh-banner-txt > span{
  font-size: 17px;
}
.fsh-checkout-banner-rectangle span.banner-txt > span {
  font-size: 13px;
  vertical-align: super;
  line-height: 20px;
  font-family: AvenirNextLTPro-Regular, 'Helvetica Neue', sans-serif !important;
}

.fsh-checkout-banner-rectangle span.banner-txt-lite {
  font-size: 15px;
  display: block;
  font-family: AvenirNextLTPro-Regular, 'Open Sans', Helvetica, sans-serif !important;
  margin-top: -7px;
}
.fsh-checkout-banner-rectangle span.fsh-banner-txt-lite{
  font-size: 19px;
}
.fsh-checkout-banner-rectangle span.fsh-banner-txt>span.fsh-txt{
  font-size: 23px;
  vertical-align: baseline;
}
#fsh-bill-banner-container {
  margin-left: 7px;
}

.fsh-checkout-banner-rectangle span.banner-sub-txt {
  font-size: 19px;
  font-weight: 300;
  display: block;
  line-height: 16px;
}

.fsh-checkout-banner-rectangle span.secure-banner-txt-lite {
  margin-top: 15px;
  letter-spacing: normal;
}
/* FSH checkout banner */
@media screen and (max-width: 768px) {
  .custom-font span > span {
    font-size: 14px !important;
  }
  .running-order-padding {
    padding: 10px 20px;
  }
  .checkout-rhs-container {
    margin-top: 47px;
  }
}

@media screen and (max-width: 481px) {
  .text-align {
    text-align: left;
    margin-bottom: 60px;
  }
  .margin-main-checkout-section {
    margin: 60px 20px;
  }
  .checkout-rhs-container {
    margin-top: 50px;
  }
  .margin-step-progress-section {
    padding: 0px;
  }
  .bottom-nav-btns-container {
    margin: 60px 20px 0px;
    padding: 0px;
  }
}
@media (min-width: 769px) {
  .align-self-end {
    -ms-flex-item-align: flex-end !important;
    align-self: flex-end !important;
  }
  .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
}

@media (min-width: 480px) {
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .justify-content-end-dsktp {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .bottom-nav-btns-container {
    margin: 0px 34px;
    padding: 30px 0px;
  }
  .checkout-nav-btns{
    padding: 20px 0px;
  }
  .full-width-container {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }
  .checkout-shade-background {
    background-color: #f4f5f8;
  }
}

.all-checks-page {

  position: relative;

  @media (min-width: 769px) {
    
    .fsh-checkout-banner-container {
      margin: 0 auto;
      width: auto;
      background: linear-gradient(83deg, #0077c5, #34bfff 113%);
      margin: 0px 34px;

      .fsh-checkout-banner-rectangle {
        padding: 11px 0px 4px 0px !important;
        background: none;
  
        .banner-txt {
          font-size: 19px;
          font-weight: 500;
        }
        .fsh-banner-txt{
          font-family: AvenirNextLTPro-Regular, 'Open Sans', Helvetica, sans-serif !important;
        }
        .banner-txt-lite {
          display: inline-block;
          line-height: 41px;
          padding: 0 7px;
        }
        span.secure-banner-txt-lite {
          margin-top: 0px;
          letter-spacing: normal;
        }
      }

      .fsh-checkout-banner-rectangle span.banner-sub-txt {
        font-size: 19px;
        font-weight: 300;
        display: inline-block;
      }
    }

    .tax-season-banner-container {
      position: absolute;
      margin: 0 0 0 34px;
      top: -19px;
      width: auto;
      background: linear-gradient(83deg, #0077c5, #34bfff 113%);
      padding: 7px;
      color: #fff;

      a,
      a:hover,
      a:visited,
      a:active {
        color: #fff;
        text-decoration: none;
      }

      &:after {
        background: linear-gradient(-45deg, #ffffff 16px, transparent 0),
          linear-gradient(45deg, #ffffff 16px, transparent 0);
        background-repeat: repeat-x;
        background-size: 9px 48px;
        content: ' ';
        display: block;
        position: absolute;
        top: 24px;
        left: 0px;
        width: 100%;
        height: 31px;
      }

      .tax-banner-txt {
        font-size: 18px;
        font-weight: 600;
      }

      .tax-banner-txt-lite {
        font-size: 16px;
      }
    }
  }
}

.sign-out-container {

  color: #393a3d;

  .margin-main-section {
    margin-top: 32px;
    margin-bottom: 48px;
  }

  .sign-out-page-heading {
    margin-bottom: 32px;
  }

  a,
  a:link,
  a:visited,
  a:active,
  a:hover {
    color: #0077C5;
    text-decoration: none;
  }
}

.terms-and-conditions {
  font-size:  12px;
  color: #393a3d;

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
}

.all-checks-page {
  .terms-and-conditions {
    margin-top: 17px;
    margin-bottom: 19px;
  }
}
.checkout-container {
  .secure-fsh span.banner-txt,
  span.banner-sub-txt {
    font-size: 22px;
    line-height: 30px;
  }
  .secure-fsh span.secure-banner-txt-lite {
    margin-top: 7px;
  }
}

@mixin p-style($fontSize: 16, $fontWeight: 600) {
  font-size: $fontSize + px;
  font-weight: $fontWeight;
  text-decoration: none;
}

@mixin promoBannerPostion {
  @media (min-width: 769px) {
    position: absolute;
    top: 0px;
    right: 34px
  }

  @media (max-width: 768px) {
    width: 90%;
    margin-top: -40px;
    margin-bottom: 40px;
  }
}

@mixin promoBanner {
  background: linear-gradient(83deg, #0077c5, #34bfff 113%);
  padding: 14px 20px 20px 20px !important; //To override the FSH banner style for checkout

  .banner-txt {
    display: block;
  }

  .banner-txt-lite {
    letter-spacing: initial;
  }

  @media (min-width: 1190px) {
    width: 132px;
    height: 120px;

    .tax-banner-txt {
      @include p-style(19, 700);
    }

    .tax-banner-txt-lite {
      @include p-style(16, 500);
    }

    .banner-txt {
      @include p-style(37, 700);
      margin-top: 5px;
      line-height: 40px;
    }

    .fsh-banner-txt{
      font-size: 40px;
      font-weight: 500;
    }

    .fsh-banner-txt .fsh-txt{
      @include p-style(40, 500);
    }

    .fsh-banner-txt-lite .promo-date{
      display: block;
    }

    .banner-txt-lite {
      @include p-style(19, 500);
      margin-top: 13px;
    }
    
    .fsh-banner-txt-lite{
      font-size: 21px;
    }

    .secure-banner-txt {
      font-size: 26px;
    }

    .secure-banner-txt-lite {
      font-size: 18px;
      letter-spacing: normal;
    }

    .banner-sub-txt {
      font-size: 26px;
      font-weight: 300;
      line-height: 30px;
      display: inline-block;
    }
  }

  @media (max-width: 1189px) {
    width: 140px;
    height: 130px;

    .tax-banner-txt {
      @include p-style(16, 700);
    }

    .tax-banner-txt-lite {
      @include p-style(14, 500);
    }

    .banner-txt {
      @include p-style(27, 700);
      margin-top: 5px;
      line-height: 30px;
    }

    .banner-txt-lite {
      @include p-style(16, 500);
      margin-top: 13px;
      line-height: 22px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 78px;
  }
}

.all_tax_form_page{
  position: relative;
  .tax-season-banner-container {
    @include promoBanner;
    @include promoBannerPostion;
    color: #fff;

    a,
    a:hover,
    a:active {
      color: #fff;
      text-decoration: none;
    }

    .banner-order-now-btn {
      background-color: #000;

      @media (max-width: 768px) {
        height: 24px;
      }
    }

    @media (min-width: 769px) {
      .banner-order-now-btn {
        margin-top: 14px;
      }
    }
  }

  .free-ship-terms{
    display:none;
  }
}

.tax-form-unavailable-msg {
  font-size: 16px;
  font-weight: 600;
  color: #0D333F;
  margin: 33px 0 0 0;
}

.xsell-tax-container {
  .tax-form-unavailable-msg {
    display: block;
    margin: 10px 0;
  }
}

.product-unavailable-msg {
  font-size: 15px;
  font-weight: 400;
  color: #8D9096;
  margin: 20px 0 0 0;
}

.tax-unavailable-pricing{
  display:block;
  margin-top:17px;
}

.most-popular {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  background-color: #07C4D9;
  padding: 2px 9px;
  border-radius: 5px;
  width: fit-content;
}