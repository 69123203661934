// Keep the media query at the end only
.endorsement-stamp-image-container {
  position: relative;
  text-align: center;
}

.monogram-stamp-image-container {
  position: relative;
  text-align: center;
}

.fax-header-stamp-image-container {
  position: relative;
}

@media (min-width: 0) {
  .endorsement-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 33%;
      left: 40%;
      font-size: 9px;
      line-height: 10px;
    }
  }

  .monogram-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 33%;
      left: 17%;
      font-size: 9px;
      text-align: left;
      width: 100%;

      .spacing-left {
        margin-left: 60px;
      }

      .monogram-wrapper {
        float: left;
        margin-right: 10px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      p {
        font-size: 10px;
        line-height: 11px;
      }
    }
  }

  .fax-header-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 32%;
      left: 6%;
      font-size: 8px;
      line-height: 26px;
      text-align: start;

      .blank-div {
        height: 26px;
      }
    }
  }
}

@media (min-width: 480px) {
  .endorsement-stamp-image-container {
    .image-company-info {
      position: absolute;
      top: 24%;
      left: 40%;
      font-size: 9px;
    }
  }

  .endorsement-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 33%;
      left: 40%;
      font-size: 9px;
      line-height: 10px;
    }
  }

  .monogram-stamp-image-container {
    .image-company-info {
      position: absolute;
      top: 24%;
      left: 22%;
      font-size: 9px;
      text-align: left;
      width: 100%;

      .spacing-left {
        margin-left: 60px;
      }

      .monogram-wrapper {
        float: left;
        margin-right: 10px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      p {
        font-size: 10px;
      }
    }
  }

  .monogram-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 33%;
      left: 35%;
      font-size: 9px;
      text-align: left;
      width: 100%;

      .spacing-left {
        margin-left: 60px;
      }

      .monogram-wrapper {
        float: left;
        margin-right: 10px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      p {
        font-size: 10px;
        line-height: 11px;
      }
    }
  }

  .fax-header-stamp-image-container {
    .image-company-info {
      position: absolute;
      top: 29%;
      left: 1%;
      font-size: 10px;
      line-height: 32px;

      .blank-div {
        height: 32px;
      }
    }
  }

  .fax-header-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 32%;
      left: 21%;
      font-size: 11px;
      line-height: 38px;
      text-align: start;

      .blank-div {
        height: 38px;
      }
    }
  }
}

@media (min-width: 769px) {
  // Printable Deposit slips - DEP=02
  .endorsement-stamp-image-container {
    .image-company-info {
      position: absolute;
      top: 24%;
      left: 40%;
      font-size: 9px;
    }
  }

  .endorsement-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 33%;
      left: 40%;
      font-size: 9px;
      line-height: 10px;
    }
  }

  .monogram-stamp-image-container {
    .image-company-info {
      position: absolute;
      top: 24%;
      left: 30%;
      font-size: 9px;
      text-align: left;
      width: 100%;

      .spacing-left {
        margin-left: 60px;
      }

      .monogram-wrapper {
        float: left;
        margin-right: 10px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      p {
        font-size: 10px;
      }
    }
  }

  .monogram-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 33%;
      left: 35%;
      font-size: 9px;
      text-align: left;
      width: 100%;

      .spacing-left {
        margin-left: 60px;
      }

      .monogram-wrapper {
        float: left;
        margin-right: 10px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      p {
        font-size: 10px;
        line-height: 11px;
      }
    }
  }

  .fax-header-stamp-image-container {
    .image-company-info {
      position: absolute;
      top: 32%;
      left: 1%;
      font-size: 12px;
      line-height: 36px;

      .blank-div {
        height: 36px;
      }
    }
  }

  .fax-header-stamp-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 32%;
      left: 20%;
      font-size: 11px;
      line-height: 38px;
      text-align: start;

      .blank-div {
        height: 36px;
      }
    }
  }
}
