// Keep the media query at the end only
.voucher-checks-image-container {
  position: relative;
  text-align: center;
  .image-company-info {
    position: absolute;
    font-size: 10px;
    height: 19px;
    width: 25%;
  }
  .logo {
    position: absolute;
    top: 2%;
    left: 4%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .sign-lines-upper-text {
    top: 20.5%;
    position: absolute;
    right: 10%;
  }
  .sign-lines-lower-text {
    top: 23.5%;
    position: absolute;
    right: 10%;
  }
  .sign-upper-line {
    position: absolute;
    top: 21.5%;
    width: 32%;
    left: 64%;
  }
  .image-summary-text {
    position: absolute;
    font-size: 10px;
    width: 85%;
    top: 35%;
    left: 5%;
    text-align: left;
  }
  .lower-image-summary-text {
    position: absolute;
    font-size: 10px;
    width: 85%;
    top: 65%;
    left: 5%;
    text-align: left;
  }
  .image-checkStartingNumber-text {
    position: absolute;
    font-size: 10px;
    width: 15%;
    top: 2.5%;
    left: 80%;
    text-align: right;
  }
  .image-csn {
    top: 34%;
  }
  .lower-csn {
    top: 65%;
  }
  .image-micr {
    position: absolute;
    top: 28%;
    left: 25%;
    font-size: 7px;

    img {
      width: 7px;
      height: 9px;
    }
  }
  .image-bank-info {
    position: absolute;
    top: 2.5%;
    left: 54%;
    line-height: 10px;
    font-size: 8px;
  }
}

.voucher-checks-modal-image-container {
  .image-company-info {
    position: absolute;
    font-size: 11px;
    width: 45%;
    line-height: 1.2;
    text-align: center;
    top: 2%;
    left: 15%;
  }
  .logo {
    position: absolute;
    top: 2%;
    left: 4%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .sign-lines-upper-text {
    top: 20.5%;
    position: absolute;
    right: 10%;
  }
  .sign-lines-lower-text {
    top: 23.5%;
    position: absolute;
    right: 10%;
  }
  .sign-upper-line {
    position: absolute;
    top: 21.5%;
    width: 32%;
    left: 64%;
  }
  .image-summary-text {
    position: absolute;
    font-size: 10px;
    width: 85%;
    top: 35%;
    left: 6%;
    text-align: left;
  }
  .image-csn {
    top: 35%;
    font-size: 15px;
  }
  .lower-csn {
    top: 65%;
    font-size: 15px;
  }
  .lower-image-summary-text {
    position: absolute;
    font-size: 10px;
    width: 85%;
    top: 65%;
    left: 6%;
    text-align: left;
  }
  .image-checkStartingNumber-text {
    position: absolute;
    font-size: 14px;
    width: 15%;
    top: 1.5%;
    left: 80%;
    text-align: right;
  }
  .image-micr {
    position: absolute;
    top: 29%;
    left: 25%;
    font-size: 11px;

    img {
      width: 6px;
      height: 9px;
    }
  }

  .image-bank-info {
    position: absolute;
    top: 2.5%;
    left: 55%;
    font-size: 10px;
    line-height: 10px;
  }
}

@media (min-width: 0) {
  .voucher-checks-modal-image-container {
    .image-company-info {
      font-size: 7px;
      line-height: 8px;
      top: 12%;
    }
    .logo {
      position: absolute;
      left: 5%;
      top: 5%;
      max-height: 60px;
      max-width: 60px;
    }
    .shadow-logo-basic-voucher {
      position: absolute;
      left: 40%;
      top: 12%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
    }
    .shadow-logo-two{
      top:42%;
    }
    .image-micr {
      position: absolute;
      top: 60%;
      left: 25%;
      font-size: 7px;

      img {
        width: 7px;
        height: 9px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 3%;
      left: 54%;
      line-height: 10px;
      font-size: 10px;
    }
  }
}

@media (min-width: 480px) {
  .voucher-checks-image-container {
    .image-company-info {
      top: 20%;
      left: 20%;
      font-size: 7px;
      line-height: 10px;
    }
    .shadow-logo-basic-voucher {
      position: absolute;
      left: 40%;
      top: 12%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
    }
    .shadow-logo-two{
      top:42%;
    }
    .image-summary-text {
      font-size: 10px;
    }
    .lower-image-summary-text {
      font-size: 10px;
    }
  }

  .voucher-checks-modal-image-container {
    .image-company-info {
      font-size: 7px;
      line-height: 10px;
    }
    .shadow-logo-basic-voucher {
      position: absolute;
      left: 40%;
      top: 12%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
    }
    .shadow-logo-two{
      top:42%;
    }
    .image-summary-text {
      font-size: 15px;
    }
    .lower-image-summary-text {
      font-size: 15px;
    }
    .image-csn {
      font-size: 15px;
      top: 35%;
    }
    .lower-csn {
      font-size: 15px;
      top: 65%;
    }
  }

  .image-micr {
    position: absolute;
    top: 29%;
    left: 25%;
    font-size: 11px;

    img {
      width: 8px;
      height: 9px;
    }
  }

  .image-bank-info {
    position: absolute;
    top: 4%;
    left: 54%;
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .voucher-checks-image-container {
    .image-company-info {
      top: 3%;
      left: 16%;
      font-size: 6px;
      line-height: 6px;
    }
    .image-summary-text {
      font-size: 10px;
      top: 35%;
    }
    .image-checkStartingNumber-text {
      font-size: 10px;
      top: 4%;
      left: 77%;
    }
    .image-csn {
      font-size: 10px;
      top: 35%;
    }
    .lower-csn {
      font-size: 10px;
      top: 65%;
    }
    .lower-image-summary-text {
      font-size: 10px;
      top: 65%;
    }
    .image-bank-info {
      font-size: 6px;
      top: 3%;
      line-height: 6px;
    }
  }
  .voucher-checks-modal-image-container {
    .image-company-info {
      top: 12%;
      left: 18%;
      font-size: 6px;
      line-height: 6px;
    }
    .image-summary-text {
      font-size: 10px;
      top: 68%;
    }
    .image-checkStartingNumber-text {
      font-size: 10px;
      top: 10%;
      left: 77%;
    }
    .image-csn {
      font-size: 10px;
      top: 68%;
    }
    .lower-csn {
      font-size: 10px;
      top: 125%;
    }
    .lower-image-summary-text {
      font-size: 10px;
      top: 125%;
    }
    .image-bank-info {
      font-size: 6px;
      top: 14%;
      line-height: 6px;
    }
    .image-micr {
      top: 55%;
      left: 18%;
      font-size: 7px;
    }
    .logo {
      position: absolute;
      left: 8%;
      top: 13%;
      width: 25px;
      height: 25px;
    }
    .shadow-logo-basic-voucher {
      position: absolute;
      left: 40%;
      top: 20%;
      flex-basis: 0;
      flex-grow: 1;
      height: 60px;
      width: auto;
    }
    .shadow-logo-two{
      top:75%;
    }
  }
}

@media (min-width: 769px) {
  .voucher-checks-image-container {
    .image-company-info {
      top: 2%;
      left: 16%;
      font-size: 8px;
      line-height: 10px;
    }
    .logo {
      position: absolute;
      left:3.5%;
      top: 2.5%;
      flex-basis: 0;
      flex-grow: 1;
      max-height: 47px;
      max-width: 60px;
    }
    .shadow-logo-basic-voucher {
      position: absolute;
      left: 30%;
      top: 10%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
    }
    .shadow-logo-two{
      top:42%;
    }
  }

  .voucher-checks-modal-image-container {
    .image-company-info {
      top: 2%;
      left: 13%;
      font-size: 11px;
      line-height: 10px;
    }
    .logo {
      position: absolute;
      left: 4%;
      top: 2%;
      max-height: 60px;
      max-width: 60px;
    }
    .shadow-logo-basic-voucher {
      position: absolute;
      left: 35%;
      top: 12%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
    }
    .shadow-logo-two{
      top:42%;
    }
    .image-micr {
      position: absolute;
      top: 28%;
      left: 20%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 2.5%;
      left: 55%;
      line-height: 10px;
      font-size: 11px;
    }
  }
}
