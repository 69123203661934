@import '../stylesheets/button.scss';
.product-page-type1 {
  .p2-regular {
    font-size: 16px;
  }

  h4,
  .product-page-dept-dec {
    font-size: 23px;
  }

  .department-desc {
    font-weight: normal;
  }

  .lnk-view-lg-img {
    font-size: 14px;
    color: #0077c5;
    cursor: pointer;
  }

  .image-column {
    margin-right: 20px;
    cursor: pointer;
  }

  .image-column img {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 98%;
    min-height: 1px;
  }

  .img-wrapper {
    margin-top: '40px';
    margin-bottom: '40px';
  }

  .check-lock {
    max-width: 100%;
    cursor: pointer;
  }

  .warning-img {
    float: left;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .color-picker {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6b6c72;
    width: 100%;
    opacity: 100;
    margin-bottom: 5px;
  }

  .margin-btm-adjust {
    margin-bottom: 60px;
  }
  // .list {
  //   padding-inline-start: 0;
  //    list-style: none;
  // }
  // .list li p:before{
  //   content:"•";
  //   font-size:10pt;
  // }

  // .list li p span{
  //   padding-left: 20px;
  // }
  .bundle-qty label {
    word-break: break-all;
  }
  // Keep the media query at the end only
  @media (min-width: 0px) {
    .dropdown-width {
      width: calc(100%);
    }

    .pricing-btn {
      width: 100%;
    }

    // .StyledTextField__InputWrapper-sc-1p2xy5w-0{
    //   width: calc(100%)
    // }

    .prod-desc-spacing {
      margin-bottom: 60px;
    }
    .prod-desc-headers,
    .middle-conten,
    .product-pricing-content {
      color: #0d333f;
    }
    .margin-main-section {
      margin: 60px 20px 60px 20px;
    }
  }

  @media (min-width: 480px) {
    .middle-content {
      margin-right: 40px;
      color: #0d333f;
    }

    h4,
    .product-page-dept-dec {
      font-size: 19px;
    }

    .p2-regular {
      font-size: 18.5px;
    }

    .dropdown-width {
      width: calc(100%);
    }

    // .StyledTextField__InputWrapper-sc-1p2xy5w-0{
    //   width: calc(100%)
    // }

    .margin-main-section {
      margin: 60px 34px 60px 34px;
    }

    .pricing-btn {
      width: 100%;
      $button-min-height: 52px;
      @include primary-dark-blue-button($button-min-height);
    }

    .department-desc {
      padding-right: 0px;
    }

    .prod-desc-spacing {
      margin-bottom: 60px;
    }
  }

  @media (min-width: 769px) {
    .middle-content {
      margin-right: 60px;
      color: #0d333f;
    }

    h4,
    .product-page-dept-dec {
      font-size: 23px;
      margin-bottom: 0px;
    }

    .p2-regular {
      font-size: 15.7px;
    }

    .p4-regular {
      font-size: 12.5px;
    }

    .dropdown-width {
      width: calc(125%);
    }

    .pricing-btn {
      width: auto;
    }

    .prod-desc-spacing {
      margin-bottom: 80px;
    }

    // .StyledTextField__InputWrapper-sc-1p2xy5w-0{
    //   width: calc(125%)
    // }
  }
  .dept-footer-text {
    font-size: 13px;
    color: #6b6c72;
  }
}

.check-fraud-header {
  color: #2ca01c !important;
  font-size: 23px;
}

.terms-condition p {
  font-size: 14px !important;
}

.check-lock-modal {
  z-index: 20000 !important;
  max-height: none !important;
  height: 100% !important;
}

.image-modal {
  z-index: 20000 !important;
  max-height: none !important;
  height: 100% !important;
}

.multi-product-page {
  .stickyNavBody {
    .product-page-type1 {
      &:nth-child(n + 2) {
        border-top: 1px solid #c5c5c5;
        margin-top: 60px;
      }
    }
  }
}

.all_tax_form_page {
  .product_list_header {
    text-align: center;
  }

  .product_list_desc {
    text-align: center;
    font-size: 19px;
  }

  .tax_pg_city_tax_section {
  }

  .stateDropdown {
    @media (min-width: 480px) {
      margin-right: 20px;
    }

    @media (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}

$bodyTextColor: #393a3d;
$linkTextColor: #0077c5;

.all-checks-page {
  .reorder-wrap {
    justify-content: flex-end;
    margin-top: -40px;
    margin-bottom: 12px;
    .all-checks-reorder-btn {
      $button-min-height: 56px;
      @include secondary-dark-blue-button($button-min-height);
    }
  }

  .price_in_list {
    .prod-sub-header {
      font-size: 19px;
      margin-top: 0px;
      margin-bottom: 0px !important;
    }
  }

  a {
    color: $linkTextColor;
    text-decoration: none;
    font-weight: bold;
    &:hover,
    &:visited {
      color: $linkTextColor;
      text-decoration: none;
    }
  }

  .top-product-col {
    color: $bodyTextColor;

    .top-product-img-wrap {
      text-align: center;
      margin-bottom: 10px;

      img {
        transform: scale(1.03);
        width: 100%;
      }
    }

    &:first-child .top-product-img-wrap {
      img {
        transform: initial;
      }
    }

    &:last-child .top-product-img-wrap {
      img {
        transform: initial;
      }
    }

    .top-product-get-started-btn {
      margin-bottom: 18px;
      margin-top: 14px;
      .top-primary-product-btn {
        $button-min-height: 52px;
        @include primary-green-button($button-min-height);
      }
      .top-secondary-product-btn {
        $button-min-height: 52px;
        @include secondary-dark-blue-button($button-min-height);
      }
    }

    .out-of-stock-msg-top-product {
      font-size: 19px;
      font-weight: 400;
      color: #8d9096;
      margin: 20px 0 0;
    }

    .top-product-features {
      ul {
        padding-left: 20px;
      }

      .top-product-feature-title {
        margin-bottom: 14px;
      }
    }
  }

  @media (min-width: 769px) {
    .top-product-col {
      max-width: 30.8%;
      margin: 0px 13px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    .header-prod-space {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 1200px) {
    .top-product-col {
      max-width: 31.5%;
      margin: 0px 15px;
    }
  }

  .stickyNavBody {
    .margin-main-section {
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .product-faq-container {
    .footer-main-section {
      padding-bottom: 60px;
    }
  }
}

.youTubeContainer {
  .youTubeVedioHeader {
    font-weight: 600;
  }

  .text-color-link {
    color: $linkTextColor;
  }

  .youTubeVedio {
    width: 764px;
    height: 420px;
    border-radius: 2px;
    border: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 60px;
    margin-bottom: 12px;

    @media (max-width: 845px) and (min-width: 550px) {
      width: 500px;
      height: 282px;
    }

    @media (max-width: 549px) {
      width: 370px;
      height: 209px;
    }
  }

  .vedioAdvLink {
    margin-top: 54px;
    margin-bottom: 73px;
  }
}
