// Keep the media query at the end only
.large-envelopes-image-container {
  position: relative;
  text-align: left;
  .image-company-info {
    position: absolute;
    font-size: 9px;
    height: 19px;
    width: 50%;
  }
  .large-mailing-envelope-logo {
    position: absolute;
    top: 5%;
    left: 4%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 85%;
    left: 2%;
    text-align: center;
  }
}

.large-envelopes-modal-image-container {
  .image-company-info {
    position: absolute;
    font-size: 9px;
    width: 50%;
    line-height: 15px;
    text-align: left;
    top: 10%;
    left: 18%;
  }
  .large-mailing-envelope-logo {
    position: absolute;
    top: 10%;
    left: 10%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 85%;
    left: 2%;
    text-align: center;
  }
}

@media (min-width: 0) {
  .large-envelopes-modal-image-container {
    .image-company-info {
    }
  }
}

@media (min-width: 480px) {
  .large-envelopes-image-container {
    .image-company-info {
      top: 20%;
      left: 20%;
    }
    .logo {
      position: absolute;
      left: 5%;
      top: 7%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
    }
  }

  .large-envelopes-modal-image-container {
    .image-company-info {
    }
    .logo {
      position: absolute;
      left: 12%;
      top: 10%;
      width: auto;
    }
  }
}

@media (min-width: 769px) {
  .large-envelopes-image-container {
    .image-company-info {
      top: 7%;
      left: 25%;
    }
  }

  .large-envelopes-modal-image-container {
    .image-company-info {
      top: 10%;
      left: 27%;
    }
  }
}
