// Keep the media query at the end only
.wallet-checks-image-container {
    position: relative;
    text-align: center;
    .image-company-info {
      position: absolute;
      font-size: 9px;
      height: 19px;
      width: 25%;
    }
    .logo {
      position: absolute;
      top: 2%;
      left: 4%;
  
      img {
        width: 50px;
        height: 50px;
      }
    }
    .sign-lines-upper-text {
      top: 15.5%;
      position: absolute;
      right: 5%;
    }
    .sign-lines-lower-text {
      top: 18%;
      position: absolute;
      right: 5%;
    }
    .sign-upper-line {
      position: absolute;
      top: 16.5%;
      width: 28%;
      left: 68%;
    }
    .image-summary-text {
      position: absolute;
      font-size: 100%;
      width: 85%;
      top: 35%;
      left: 5%;
      text-align: left;
    }
    .lower-image-summary-text {
      position: absolute;
      font-size: 100%;
      width: 85%;
      top: 65%;
      left: 5%;
      text-align: left;
    }
    .image-checkStartingNumber-text {
      position: absolute;
      font-size: 10px;
      width: 15%;
      top: 2%;
      left: 80%;
      text-align: right;
    }
    .image-micr {
      position: absolute;
      top: 23%;
      left: 35%;
      font-size: 7px;
  
      img {
        width: 7px;
        height: 9px;
      }
    }
    .image-bank-info {
      position: absolute;
      top: 2%;
      left: 58%;
      line-height: 10px;
      font-size: 10px;
    }
    .check-bottom-section{
      .align-info-bottom {
        top: 53.4%;
      }
      .align-info-basic-bottom {
        top: 54%;
      }
      .align-micr-bottom {
        top: 73.5%;
      }
      .align-sign-bottom-uppertext {
        top: 66.5%;
      }
      .align-sign-bottom-line {
        top: 67.5%;
      }
      .align-sign-bottom-lowertext {
        top: 69.5%;
      }
    } 
    .check-middle-section{
      .align-info-middle {
        top: 27.5%;
      }
      .align-micr-middle {
        top: 48%;
      }
      .align-sign-middle-uppertext {
        top: 41%;
      }
      .align-sign-middle-line {
        top: 42%;
      }
      .align-sign-middle-lowertext {
        top: 43.5%;
      }
    }
  }
  
  .wallet-checks-modal-image-container {
    .image-company-info {
      position: absolute;
      font-size: 9px;
      width: 30%;
      line-height: 15px;
      text-align: center;
      top: 4%;
      left: 18%;
    }
    .logo {
      position: absolute;
      top: 5%;
      left: 5%;
  
      img {
        width: 50px;
        height: 50px;
      }
    }
    .sign-lines-upper-text {
      top: 16%;
      position: absolute;
      right: 5%;
    }
    .sign-lines-lower-text {
      top: 18%;
      position: absolute;
      right: 5%;
    }
    .sign-upper-line {
      position: absolute;
      top: 16.5%;
      width: 28%;
      left: 68%;
    }
    .image-summary-text {
      position: absolute;
      font-size: 100%;
      width: 85%;
      top: 35%;
      left: 10%;
      text-align: left;
    }
    .lower-image-summary-text {
      position: absolute;
      font-size: 100%;
      width: 85%;
      top: 65%;
      left: 10%;
      text-align: left;
    }
    .image-checkStartingNumber-text {
      position: absolute;
      font-size: 15px;
      width: 15%;
      top: 2%;
      left: 80%;
      text-align: right;
    }
    .image-micr {
      position: absolute;
      top: 29%;
      left: 25%;
      font-size: 11px;
  
      img {
        width: 6px;
        height: 9px;
      }
    }
    .image-bank-info {
      position: absolute;
      top: 5%;
      left: 50%;
      font-size: 8px;
      line-height: 9px;
    }
    .check-bottom-section{
      .align-info-bottom {
        top: 53.5%;
      }
      .align-info-basic-bottom{
        top: 53.4%;
      }
      .align-micr-bottom {
        top: 74%;
      }
      .align-sign-bottom-uppertext {
        top: 67%;
      }
      .align-sign-bottom-line {
        top: 67.5%;
      }
      .align-sign-bottom-lowertext {
        top: 69.5%;
      }
    } 
    .check-middle-section{
      .align-info-middle {
        top: 28%;
      }
      .align-micr-middle {
        top: 48.5%;
      }
      .align-sign-middle-uppertext {
        top: 41.5%;
      }
      .align-sign-middle-line {
        top: 42%;
      }
      .align-sign-middle-lowertext {
        top:44%;
      }
    }
  }
  
  @media (min-width: 0) {
    .wallet-checks-modal-image-container {
      .image-company-info {
      }
      .logo {
        position: absolute;
        left: 5%;
        top: 5%;
        max-height: 60px;
        max-width: 60px;
      }
      .image-micr {
        position: absolute;
        top: 60%;
        left: 25%;
        font-size: 7px;
  
        img {
          width: 7px;
          height: 9px;
        }
      }
  
      .image-bank-info {
        position: absolute;
        top: 2%;
        left: 54%;
        line-height: 10px;
        font-size: 8px;
      }
    }
  }
  
  @media (min-width: 480px) {
    .wallet-checks-image-container {
      .image-company-info {
        top: 20%;
        left: 20%;
      }
    }
  
    .wallet-checks-modal-image-container {
      .image-company-info {
      }
    }
  
    .image-micr {
      position: absolute;
      top: 29%;
      left: 25%;
      font-size: 11px;
  
      img {
        width: 8px;
        height: 9px;
      }
    }
  
    .image-bank-info {
      position: absolute;
      top: 4%;
      left: 54%;
      font-size: 10px;
    }
  }
  
  @media (min-width: 769px) {
    .wallet-checks-image-container {
      .image-company-info {
        top: 2%;
        left: 30%;
        line-height:10px;
      }
      .image-company-info > div {
        font-size: 8px!important;
      }
      .logo {
        position: absolute;
        left: 19%;
        top: 2%;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 60px;
        max-height: 42px;
      }
    }
  
    .wallet-checks-modal-image-container {
      .image-company-info {
        top: 2%;
        left: 30%;
        line-height: 12px;
      }
      .image-company-info > div {
        font-size: 10px!important;
      }
      .logo {
        position: absolute;
        left: 19%;
        top: 2%;
        width: 60px;
      }
      .image-micr {
        position: absolute;
        top: 23%;
        left: 36%;
        font-size: 11px;
  
        img {
          width: 10px;
          height: 12px;
        }
      }
  
      .image-bank-info {
        position: absolute;
        top: 2%;
        left: 58%;
        line-height: 12px;
        font-size: 10px;
      }
    }
  }
  