.footerInfo {
  background: #393a3d;
  li a {
    font-size: 16px;
  }
  .other-product {
    padding: 34px;
    padding-top: 20px;
    padding-bottom: 0px;
  }
  .manage-cookie > a {
    display: inline-block;
    margin: 0px;
    color: #FFFFFF;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .container {
      width: 1200px;
      margin: 0px auto;
    }
  }
  h3 {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: #FFFFFF;
    display: block;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .search-query {
    background: transparent;
    border: 1px solid #babec5;
    height: 27px;
    border-radius: 4px;
    color: #eceef1;
    text-align: left;
    width: 80%;
    text-indent: 25px;
  }

  .add-on {
    background-color: transparent;
    background-image: url('../assets/images/IM/Footer/ic-footer-search.svg');
    background-repeat: no-repeat;
    top: -24px;
    left: 5px;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 0;
    position: relative;
    cursor: pointer;
  }

  .product-category {
    margin-bottom: 40px;
    list-style: none;
    padding: 0px;
  }
  .clearspace {
    margin-bottom: 40px;
  }
  span.ft-cust-service {
    display: block;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;
    color: #D4D7DC;
  }
  span.ft-cust-service.ft-cust-style {
    color: #eceef1;
    margin-bottom: 0px;
    font-weight: 500;
  }
  .find-order {
    margin-top: 40px;
    font-size: 16px;
    color: #eceef1;
    width: 100%;
  }
  .term-service {
    margin-top: 20px;
    font-size: 16px;
    color: #eceef1;
    width: 100%;
  }
  .manage-cookie {
    border-top: 1px solid #8d9096;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .cookie-info {
    padding: 0 34px 0 34px;
    padding-top: 0px;
    font-size: 16px;
  }
  .oi-footer-global-logoLinks {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 1px;
  }
  .oi-footer-global-logo {
    height: 45px;
    width: 80%;
    margin-bottom: 20px;
    background-size: 100%;
  }
  .intuit-info {
    padding: 0 34px 80px;
  }
  .ctext {
    font-size: 14px;
    color: #ffffff;
  }

  .products-icon-quickbooks {
    background-image: url('../assets/images/IM/Footer/brandbar_QB.svg');
    width: 113px;
    height: 20px;
    background-position: 0 -1154px;
  }
  .footer-global-legalLinks {
    margin-bottom: 60px;
  }
  .footer-global-legalLinks a {
    display: inline;
  }
  .im-name {
    color: #ffffff;
    font-size: 19px;
    margin-bottom: 20px;
    border: none;
    background: none;
    padding-left: 0;
    font-weight: 600;
  }

  .resouce-center {
    list-style: none;
    padding: 0;
  }
  .bullet-spacing {
    padding: 0 8px 0 8px;
    display: inline;
  }
  .is-available {
    display: none;
  }

  .qb-logo {
    background-image: url('../assets/images/IM/Footer/brandbar_QB.svg');
    background-repeat: no-repeat;
    width: 140px;
    height: 25px;
    display: inline-block;
    background-position: 0px 0px;
    margin: 0px;
  }
  .tt-logo {
    background-image: url('../assets/images/IM/Footer/brandbar_QB.svg');
    background-repeat: no-repeat;
    width: 140px;
    height: 25px;
    display: inline-block;
    background-position: -165px 0px;
    margin: 0px;
  }
  .pro-logo {
    background-image: url('../assets/images/IM/Footer/brandbar_QB.svg');
    background-repeat: no-repeat;
    width: 140px;
    height: 25px;
    display: inline-block;
    background-position: -310px 0px;
    margin: 0px;
  }
  .ck-logo {
    background-image: url('../assets/images/IM/Footer/brandbar_QB.svg');
    background-position: -478px 0px;
    background-repeat: no-repeat;
    width: 140px;
    height: 25px;
    display: inline-block;
    margin: 0px;
  }
  .is-available-icon {
    display: none;
  }
  .dropdown-content a {
    font-size: 16px;
  }
  
  .oifooter-c-divider {
      margin: 0 10px;
      border-left: 1px solid #fff;
      vertical-align: middle;
  }
  .oi-footer-global-legal > a {
    display: inline-block;
    text-decoration: underline;
  }
  .manage-cookies-data, .do-not-share-personal-info {
    display: flex;
    text-decoration: underline;
    color: #FFFFFF;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    border: none;
    background: none;
  }
  @media (min-width: 768px) {
    div.im-name > :first-child {
      display: block;
    }
    .oi-footer-global-legal {
      margin-right: 70px;
    }
    .oi-footer-global-legal-cookie {
      margin-right: 70px;
      display: flex;
      height: 18px;
    }

    .terms-data {
      display: inline-block;
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    .find-order {
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      margin-bottom: 0;
    }
    .search-type {
      margin-bottom: 0px;
    }
    .resouce-center {
      list-style: none;
      display: none;
      margin-left: -34px;
      margin-right: -34px;
      background: white;
      margin-top: 0;
      margin-bottom: 0;
      border-top: 1px solid grey;
    }
    .resouce-center:hover {
      display: block;
      border: 1px solid white;
      margin-left: -34px;
      margin-right: -34px;
      background: white;
      margin-top: 0;
      margin-bottom: 0;
    }
    .border-line {
      border-top: 1px solid #eceef1;
    }
    .responsive-dropdown {
      margin-left: 34px;
      margin-right: 34px;
      padding: 20px;
      color: #393a3d;
      font-size: 18px;
      margin-bottom: 0px;
    }
    .responsive-dropdown:hover {
      background: #eceef1;
    }
    .responsive-data {
      padding-bottom: 20px;
      border-top: 1px solid grey;
      padding-top: 16px;
      position: relative;
    }
    .clearspace {
      margin: 0px;
    }
    .product-category {
      list-style: none;
      height: 0px;
      display: none;
      margin-bottom: 0;
    }
    .legel-info {
      display: inline-block;
      vertical-align: top;
      margin: 0px;
      margin-right: 20px;
    }
    .truste-img {
      width: 100%;
    }
    span.ft-cust-service {
      margin-bottom: 0px;
    }
    .footer-global-legalLinks a {
      margin-top: 12px;
      display: inline-block;
    }
    .im-name {
      font-size: 16px;
      color: #eceef1;
      margin-bottom: 0px;
    }
    .margin-data {
      margin-bottom: 20px;
    }
    .icon-dropdown {
      background-image: url('../assets/images/IM/Footer/arrow-down-white.svg');
      width: 26px;
      height: 18px;
      background-repeat: no-repeat;
      background-color: transparent;
      border: 0px;
      position: absolute;
      right: 0;
    }
    .is-available-icon {
      display: inline-block;
    }
    .dropbtn:hover + ul.resouce-center {
      display: block !important;
    }
    .trust-img-background {
      width: 23%;
      display: inline-block;
    }
    .drop-down-data {
      background-color: white;
      margin-left: -34px;
      margin-right: -34px;
    }
    .responsive-data:hover + ul.drop-down-data {
      height: auto;
      display: block;
    }
    .drop-down-content {
      height: 40px;
      padding-left: 54px;
      padding-top: 20px;

      border-bottom: 1px solid #e0e0e0;
    }
    .drop-down-content:hover {
      background-color: #eceef1;
    }
    .drop-down-content a {
      color: black;
    }
    .dropdown-content a {
      font-size: 16px;
      padding: 20px 0px 20px 54px;
      margin: 0px;
      border-bottom: 1px solid #e0e0e0;
      color: #393a3d;
      margin-left: -34px;
      margin-right: -34px;
    }
    .dropdown-content a:hover {
      background: #eceef1;
    }
    .dropdown-content {
      display: none;
      background: white;
      margin-left: -34px;
      margin-right: -34px;
      padding-left: 34px;
      padding-right: 34px;
    }
    .dropdown:hover .icon-dropdown {
        background-image: url('../assets/images/IM/Footer/arrow-up-white.svg');
        background-repeat: no-repeat;
        background-color: transparent;
        display: inline-block;
        width: 26px;
        height: 18px;
    }
    .dropdown:hover + .dropdown-content {
      display: block;
    }
    .term-service {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 480px) {
    .find-order {
      border-top: 1px solid grey;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .other-product {
      padding: 34px 20px 0 20px;
    }
    .responsive-data {
      padding-bottom: 20px;
      border-top: 1px solid grey;
      padding-top: 16px;
      position: relative;
    }
    .clearspace {
      margin: 0px;
    }
    .product-category {
      list-style: none;
      height: 0px;
      display: none;
    }
    .search-query {
      width: 100%;
    }
    .footer-link > a {
      display: inline;
    }
    .footer-link :first-child {
      padding-left: 0px;
    }
    .footer-info-margin {
      margin-bottom: 40px;
      margin-top: 40px;
    }
    .legel-info {
      display: inline-block;
      margin: 0px;
      margin-right: 20px;
    }
    .truste-img {
      width: 100%;
      margin: 0px;
    }
    .trust-img-background {
      width: 23%;
      display: inline-block;
    }
    .is-available {
      display: inline-block;
    }
    .terms-data {
      display: inline-block;
      text-decoration: underline;
    }
    .margin-data {
      margin-bottom: 20px;
    }
    .is-available-icon {
      display: inline-block;
    }
    .oi-footer-global-logo {
      width: 60%;
    }
    .cookie-info{
      padding: 20px;
      padding-top: 0px;
    }
    .intuit-info {
      padding: 0 20px 80px;
    }
  }
}

.privacy-option {
  width: 25px;
  padding-left: 5px;
}