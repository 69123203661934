// Keep the media query at the end only
.personal-checks-image-container {
  position: relative;
  text-align: center;
    .image-company-info {
      position: absolute;
      font-size: 10px;
      height: 19px;
      width:35%;
    }
    .image-checkStartingNumber-text {
      position: absolute;
      font-size: 100%;
      width: 15%;
      top: 3%;
      left: 80%;
      text-align: right;
    }
    .image-micr {
      position: absolute;
      top: 28%;
      left: 25%;
      font-size: 7px;

      img {
        width: 7px;
        height: 9px;
      }
    }
    .image-bank-info {
      position: absolute;
      top: 3%;
      left: 54%;
      line-height: 7px;
      font-size: 7px;
    }
    .account-open-date{
        position: absolute;
        top: 9%;
        left: 50%;
        font-size: 10px;
    }
}

.personal-checks-modal-image-container {
    .image-company-info {
      position: absolute;
      font-size: 9px;
      width: 35%;
      line-height: 15px;
      text-align: center;
      top: 10%;
      left: 18%;
    }
 
    .image-checkStartingNumber-text {
        position: absolute;
        font-size: 100%;
        width: 15%;
        top: 5%;
        left: 80%;
        text-align: right;
    }
    .image-micr {
      position: absolute;
      top: 29%;
      left: 25%;
      font-size: 11px;

      img {
        width: 6px;
        height: 9px;
      }
    }
  .image-bank-info {
    position: absolute;
    top: 5%;
    left: 50%;
    font-size: 8px;
    line-height: 9px;
  }
  .account-open-date {
    position: absolute;
    top: 9%;
    left: 50%;
    font-size: 12px;
  }
}

@media (min-width: 0) {
  .personal-checks-modal-image-container {
    .image-company-info {
    }
    .image-micr {
      position: absolute;
      top: 60%;
      left: 25%;
      font-size: 7px;

      img {
        width: 7px;
        height: 9px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 3%;
      left: 54%;
      line-height: 7px;
      font-size: 7px;
    }
  }
}

@media (min-width: 480px) {
  .personal-checks-image-container {
    .image-company-info {
      top: 20%;
      left: 20%;
    }
  }

  .personal-checks-modal-image-container {
    .image-company-info {
    }
  }

  .image-micr {
    position: absolute;
    top: 29%;
    left: 25%;
    font-size: 11px;

    img {
      width: 8px;
      height: 9px;
    }
  }

  .image-bank-info {
    position: absolute;
    top: 4%;
    left: 54%;
    font-size: 7px;
  }
}

@media (min-width: 769px) {
  .personal-checks-image-container {
    .image-company-info {
  		top: 9%;
    	left: 5%;
      text-align: left;
      line-height: 10px;
    }
    .image-micr {
        position: absolute;
        top: 88%;
        left: 10%;
        font-size: 7px;
    }
    .image-bank-info {
        position: absolute;
        top: 60%;
        left: 5%;
        line-height: 10px;
        font-size: 10px;
        text-align: left;
    }
    .image-checkStartingNumber-text {
        position: absolute;
        font-size: 100%;
        width: 15%;
        top: 9%;
        left: 80%;
        text-align: right;
    }
  }

  .personal-checks-modal-image-container {
    .image-company-info {
    	top: 9%;
      left: 7%;
      font-size:12px
    }
    
    .image-checkStartingNumber-text {
        position: absolute;
        font-size: 15px;
        width: 15%;
        top: 9%;
        left: 78%;
        text-align: right;
    }
    .image-micr {
      position: absolute;
      top: 86%;
      left: 10%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 60%;
      left:7%;
      line-height: 15px;
      font-size: 12px;
      text-align: left;
    }
  }
}
