.img-col img {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 98%;
  min-height: 1px;
}

.pnf-header {
  margin: 10px 0px 52px;
  font-size: 44px;
  color: #393a3d;
  font-weight: 700;
}

.pnf-sub-header {
  font-size: 28px;
  font-weight: 600;
  color: #393a3d;
}

.pnf-sub-section {
  margin-bottom: 50px;
}

.pnf-link {
  text-decoration: none;
}

.pnf-text-link {
  font-size: 24px;
  color: #0077c5;
  font-weight: 600;
  line-height: 1.5;
}

.pnf-contact {
  font-size: 24px;
  font-weight: 600;
  color: #393a3d;
  margin-bottom: 80px;
}
