.custom-text-label{
    font-size: 16px;
    font-weight: 400;
    color: #393A3D;
}

.custom-text-field{
 /* Chrome, Safari, Edge, Opera */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }

 /* Firefox */
 input[type=number] {
     -moz-appearance: textfield;
 }
}

.product-unavailable-modal {

    $boldFont: 700;

    @mixin linkStyle () {
        text-decoration: none;
        color: #0077c5;
    }

    &[data-automation-id="productUnavailableModalDialog"] {
        width: 640px;
        color: #393A3D;
    }

    .product-unavailable-modal-title {
        font-size: 24px;
        font-weight: $boldFont;
        padding: 11px 0 0 20px;
        
    }

    .product-unavailable-modal-link {
        font-weight: $boldFont;
        display: block;
        margin-bottom: 34px;
        margin-top: 38px;
        @include linkStyle();

        &:hover, &:active, &:visited {
            @include linkStyle();
        }
    }
}