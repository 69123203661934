// CSS used for across web application
// [class^='StyledModalDialog__InnerContainer'] {
//   position: relative;
//   width: 100%;
//   text-align: center;
//   padding: 20px;
//   margin: 0px 20px;
//   outline: none;
// }
@import '../stylesheets/product.scss';
@import '../stylesheets/checkout/common.scss';

body {
  margin: 0px !important;
}
@media (min-width: 0) {
  h1 {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
  }

  h2 {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }

  h4 {
    font-size: 19px;
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
}

@media (min-width: 480px) {
  h1 {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
  }

  h2 {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }

  h4 {
    font-size: 19px;
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
}

@media (min-width: 769px) {
  h1 {
    font-size: 44px;
    font-weight: 700;
    margin: 0;
  }

  h2 {
    font-size: 36px;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 19px;
    font-weight: 400;
    margin: 0;
  }
}
.gutter {
  margin: 0px;
}
.scroll-top-container {
  bottom: 8%;
  right: 1%;
  position: fixed;
  z-index: 99999;
}

.scroll-top-container button img {
  height: 50px;
  width: 50px;
}
.fade-out {
  width: 0;
  height: 0;
  transition: width 0.5s, height 0.5s;
}
.fade-in {
  width: 50px;
  height: 50px;
  transition: width 0.5s, height 0.5s;
  opacity: 0.37;
}
.fade-in:hover {
  opacity: 0.72;
  cursor: pointer;
}
.phone-icon-content-section {
  margin-top: 150px;
  top: 150px;
  margin-right: -148px;
  right: 0px;
  position: fixed;
}
.phone-icon-content-section:hover {
  margin-right: 0px;
}
.phone-icon-content-section:focus {
  margin-right: 0px;
}
.phone-callout {
  height: 40px;
}
.contact-us {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
}
.universal-link {
  color: #0077C5;
  text-decoration: none;
  font-weight: 600;
}
.home-component {
  text-align: center;
  padding-top: 5em;
  padding-bottom: 5em;
}
.video-container {
  margin-top: 60px;
  margin-bottom: 20px;
}
.video-container iframe {
  height: 420px;
  width: 746px;
}
.base-link {
  color:#0077C5;
  cursor: pointer;
}
.base-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.secondary-link {
  color:#393A3D;
  cursor: pointer;
}
.secondary-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.loader{
  z-index: 2525;
  position: fixed;
  margin-top: 20%;
  margin-left: -5%;  
  margin-bottom: 20%;
}

.loader-p{
  z-index: 2525;
  //position: fixed;
  font-weight: 600;
  color: #53b700;
  //left: 50%;
  //transform: translate(-50%, 0);
  //margin-left:calc(-2%);
  //margin-top: calc(-15%);
}

@media screen and (max-width: 800px) {
  .video-container iframe {
    height: 100%;
    width: 100%;
  }
}
.product-type {
       width: 364px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    height: 445px;
    background: #f2f2f2;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: center;
    font-weight: 400;
}
.dropdown-background-color-white > div {
  background-color: #ffff;
}
.dropdown-background-color-white-label >div>label>div {
  background-color: #ffff;
}
