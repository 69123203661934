// Keep the media query at the end only
.form-envelopes-image-container {
  position: relative;
  text-align: center;
  .image-company-info {
    position: absolute;
    font-size: 9px;
    line-height: 100%;
    text-align: center;
    width:30%;
  }
  .logo {
    position: absolute;
    left: 5%;
    top: 7%;
    flex-basis: 0;
    flex-grow: 1;
    max-height: 60px;
    max-width: 60px;
  }
}

.form-envelopes-modal-image-container {
  .image-company-info {
    position: absolute;
    font-size: 9px;
    width: 40%;
    line-height: 100%;
    text-align: center;
  }
  .logo {
    position: absolute;
    left: 10%;
    top: 15%;
    width: auto;
  }
}
@media (min-width: 0) {
  .form-envelopes-modal-image-container {
    .image-company-info {
      top: 10%;
      left: 10%;
    }
  }
}

@media (min-width: 480px) {
  .form-envelopes-image-container {
    .image-company-info {
      top: 20%;
      left: 20%;
    }
  }

  .form-envelopes-modal-image-container {
    .image-company-info {
    }
  }
}

@media (min-width: 769px) {
  .form-envelopes-image-container {
    .image-company-info {
      top: 10%;
      left: 15%;
    }
  }

  .form-envelopes-modal-image-container {
    .image-company-info {
      top: 15%;
      left: 13%;
    }
  }
}
