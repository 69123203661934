// Keep the media query at the end only
.standard-checks-image-container {
  position: relative;
  text-align: center;
  .image-company-info {
    position: absolute;
    font-size: 9px;
    height: 19px;
    width: 25%;
  }
  .logo {
    position: absolute;
    top: 2%;
    left: 4%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .sign-lines-upper-text {
    top: 20.5%;
    position: absolute;
    right: 10%;
  }
  .sign-lines-lower-text {
    top: 23.5%;
    position: absolute;
    right: 10%;
  }
  .sign-upper-line {
    position: absolute;
    top: 21.5%;
    width: 32%;
    left: 64%;
  }
  .image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 35%;
    left: 5%;
    text-align: left;
  }
  .lower-image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 65%;
    left: 5%;
    text-align: left;
  }
  .image-checkStartingNumber-text {
    position: absolute;
    font-size: 10px;
    width: 15%;
    top: 2.5%;
    left: 80%;
    text-align: right;
  }
  .image-micr {
    position: absolute;
    top: 28%;
    left: 25%;
    font-size: 7px;

    img {
      width: 7px;
      height: 9px;
    }
  }
  .image-bank-info {
    position: absolute;
    top: 2.5%;
    left: 54%;
    line-height: 10px;
    font-size: 10px;
  }
  .check-bottom-section{
    .align-info-bottom {
      top: 66%;
    }
    .align-logo-bottom {
      top: 66.5%;
    }
    .align-info-basic-bottom {
      top: 66%;
    }
    .align-micr-bottom {
      top: 91.5%;
    }
    .align-sign-bottom-uppertext {
      top: 84%;
    }
    .align-sign-bottom-line {
      top: 85%;
    }
    .align-sign-bottom-lowertext {
      top: 87%;
    }
  } 
  .check-middle-section{
    .align-info-middle {
      top: 34.2%;
    }
    .align-logo-middle {
      top: 35%;
    }
    .align-micr-middle {
      top: 60%;
    }
    .align-sign-middle-uppertext {
      top: 52.5%;
    }
    .align-sign-middle-line {
      top: 53.5%;
    }
    .align-sign-middle-lowertext {
      top: 55.5%;
    }
  }
}

.standard-checks-modal-image-container {
  .image-company-info {
    position: absolute;
    font-size: 9px;
    width: 30%;
    line-height: 15px;
    text-align: center;
    top: 2.5%;
    left: 18%;
  }
  .logo {
    position: absolute;
    top: 5%;
    left: 5%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .sign-lines-upper-text {
    top: 20.5%;
    position: absolute;
    right: 12%;
  }
  .sign-lines-lower-text {
    top: 23.5%;
    position: absolute;
    right: 12%;
  }
  .sign-upper-line {
    position: absolute;
    top: 21.5%;
    width: 30%;
    left: 63%;
  }
  .image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 35%;
    left: 10%;
    text-align: left;
  }
  .lower-image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 65%;
    left: 10%;
    text-align: left;
  }
  .image-checkStartingNumber-text {
    position: absolute;
    font-size: 17px;
    width: 15%;
    top: 2.5%;
    left: 78%;
    text-align: right;
  }
  .image-micr {
    position: absolute;
    top: 29%;
    left: 25%;
    font-size: 11px;

    img {
      width: 6px;
      height: 9px;
    }
  }
  .image-bank-info {
    position: absolute;
    top: 2.5%;
    left: 50%;
    font-size: 8px;
    line-height: 9px;
  }
  .check-bottom-section{
    .align-info-bottom {
      top: 66%;
    }
    .align-logo-bottom {
      top: 66%;
    }
    .align-info-basic-bottom{
      top: 66%;
    }
    .align-micr-bottom {
      top: 91%;
    }
    .align-sign-bottom-uppertext {
      top: 84%;
    }
    .align-sign-bottom-line {
      top: 85%;
    }
    .align-sign-bottom-lowertext {
      top: 87%;
    }
  } 
  .check-middle-section{
    .align-info-middle {
      top: 34.5%;
    }
    .align-logo-middle {
      top: 34.5%;
    }
    .align-micr-middle {
      top: 59%;
    }
    .align-sign-middle-uppertext {
      top: 52%;
    }
    .align-sign-middle-line {
      top: 53%;
    }
    .align-sign-middle-lowertext {
      top: 55%;
    }
  }
}

@media (min-width: 0) {
  .standard-checks-modal-image-container {
    .image-company-info {
    }
    .logo {
      position: absolute;
      left: 5%;
      top: 5%;
      max-height: 60px;
      max-width: 60px;
    }
    .shadow-logo {
      position: absolute;
      left: 28%;
      top: 43%;
      width: 150px;
    }
    .image-micr {
      position: absolute;
      top: 60%;
      left: 25%;
      font-size: 7px;

      img {
        width: 7px;
        height: 9px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 3%;
      left: 54%;
      line-height: 10px;
      font-size: 10px;
    }
  }
}

@media (min-width: 480px) {
  .standard-checks-image-container {
    .image-company-info {
      top: 20%;
      left: 20%;
    }

    .shadow-logo {
      position: absolute;
      left: 28%;
      top: 43%;
      width: 150px;
    }
  }

  .standard-checks-modal-image-container {
    .image-company-info {
    }
    .shadow-logo {
      position: absolute;
      left: 30%;
      top: 49%;
      width: initial;
    }
  }

  .image-micr {
    position: absolute;
    top: 29%;
    left: 25%;
    font-size: 11px;

    img {
      width: 8px;
      height: 9px;
    }
  }

  .image-bank-info {
    position: absolute;
    top: 2.5%;
    left: 54%;
    font-size: 10px;
  }
}

@media (min-width: 769px) {
  .standard-checks-image-container {
    .image-company-info {
      top: 2.5%;
      left: 16%;
      line-height: 10px;
    }
    .logo {
      position: absolute;
      left: 3.5%;
      top: 2.5%;
      flex-basis: 0;
      flex-grow: 1;
      max-height: 47px;
      max-width: 60px;
    }
    .shadow-logo {
      position: absolute;
      left: 30%;
      top: 9%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
    }
  }

  .standard-checks-modal-image-container {
    .image-company-info {
      top: 2.5%;
      left: 18%;
    }
    .logo {
      position: absolute;
      left: 6%;
      top: 2.5%;
      max-height: 60px;
      max-width: 60px;
    }
    .shadow-logo {
      position: absolute;
      left: 30%;
      top: 11%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
    }
    .image-micr {
      position: absolute;
      top: 28%;
      left: 20%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 2.5%;
      left: 50%;
      line-height: 12px;
      font-size: 11px;
    }
  }
}
