.customization-header {
  font-size: 44px;
  font-weight: bold;
  color: #393a3d;
}

.reorder-customization-container {
  margin-bottom: 60px;
}

.reorder-customization-header-container {
  margin-left: 30px;
}

.reorder-customization-header {
  font-size: 28px;
  font-weight: bold;
  color: #393a3d;
}

.reorder-desp-list {
  margin-top: 40px;
  li {
    font-size: 24px;
  }
}

.reorder-text {
  font-size: 16px;
  margin: 40px 0 0px;
  font-weight: bold;
  color: #393a3d;
}

.step-title {
  font-weight: 300;
  font-size: 27px;
  padding-left: 20px;
}

.component-header {
  font-size: 24px;
  font-weight: 500;
  color: #393a3d;
}

.summarytext {
  width: 85% !important;
  display: inline-block;
  margin-right: 20px;
}
.sheet-picker {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #6b6c72;
  width: 100%;
  opacity: 100;
  margin-bottom: 5px;
}

.text-label {
  font-size: 16px;
  font-weight: 400;
  color: #393a3d;
}
.circle-info-span {
  vertical-align: middle;
}
.ids-text-field {
  label {
    font-size: 16px;
    font-weight: 400;
    color: #393a3d;
  }
}

.custom-ids-text-field {
  width: 70% !important;
  margin-bottom: 10px;
  margin-right: 20px;
  display: inline-block;
  font-size: 16px;
}
.width-font-size {
  display: inline-block;
  width: 52px !important;
  margin-right: 20px;
}
.company-info-header {
  font-size: 16px;
}
div.width-font-size input {
  padding: 8px 3px 8px 4px !important;
}
.width-font-size >div >label >div >div {
  margin-left: 0;
  margin-right: 0px;
}
.optinal-text {
  display: block;
  font-size: 19px;
}
.width-font-weight {
  display: inline-block;
}
div.width-font-weight > label {
  margin: 0px;
}
.width-reverse-tag {
  margin-top: 20px;
  font-size: 12px;
}
div.width-reverse-tag > label {
  vertical-align: middle;
  margin-bottom: 0px;
  margin-right: 8px;
}
.selected-sheet {
  font-weight: 600 !important;
  font-size: 17px;
  color: black;
}

.section-divider {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid #babec5;
}

.width-100-dropdown {
  width: 100% !important;

  .idsDropdownTextField {
    width: 100% !important;
  }
}

.step-title {
  font-size: 24px;
}

.customization-wrapper h3 {
  font-size: 24px;
}

.step-title-section h1 {
  font-size: 36px;
  color: #393a3d;
}

.image-preview-modal {
  z-index: 20000 !important;
  max-height: none !important;
  height: 100% !important;
  width: 97%;
}

.image-preview-modal-scroll {
  z-index: 20000 !important;
  height: 100% !important;
  width: 100% !important;
}

.company-information-text-wrapper {
  border: 1px solid rgba(141, 144, 150, 1) !important;
  border-radius: 4px;
}

.text-field-hide-border {
  input {
    border: 1px solid white !important;
  }
}

.cust-upload-modal-header {
  color: #393a3d;
  padding-bottom: 3px;
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 30px;
  font-weight: 600;
  text-align: left;
}
.add-logo-options-container{
  margin-top:-16px;
}
.logo-header{
  font-size: 36px;
  font-weight: 700;
  color: #393a3d;
}
.cust-upload-modal-text{
  font-size: 19px;
  color: #393a3d;
  font-weight: 500;
}
.logo-option-title{
  font-size: 24px;
  font-weight: 700;
  color: #393a3d;
}
.logo-option-sub-title{
  font-size: 16px;
  color: #393a3d;
}

.cust-upload-header{
  margin-top:3px;
}
.cust-upload-header button span {
  padding: 0px;
  font-size:19px;
}
.upload-sub-header{
  margin-bottom:32px
}
.cust-close-upload {
  float: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  cursor: pointer;
}

.logo-section-divider {
  margin: 20px 0;
}

.logo-catalog-container {
  display: flex;
}

.monogram-tile .ehFWp svg,
.monogram-tile .ehFWp img {
  height: auto;
  width: auto;
  max-height: 79px;
  fill: none;
}

.logo-catalog {
  margin: 0 0 35px;
  color: #0077c5;
  font-weight: 600;
  display: block;
  text-decoration: none;
}

.menu-on-modal:parent {
  z-index: 1300;
}

.menu-on-modal {
  z-index: 1300;
  div {
    z-index: 1300;
  }
}

.blue {
  color: rgb(0, 51, 153);
}
.green {
  color: rgb(0, 102, 51);
}
.maroon {
  color: rgb(153, 0, 0);
}
.red {
  color: rgb(255, 0, 0);
}
.violet {
  color: rgb(102, 0, 153);
}
.auriol {
  font-family: auriol;
}
.calvert {
  font-family: calvert;
}
.cascadescript {
  font-family: cascadescript;
}
.helvetica {
  font-family: helvetica;
}
.improv {
  font-family: improv;
}
.kaufmann {
  font-family: kaufmann;
}
.hobo {
  font-family: hobo;
}
.korinna {
  font-family: korinthia;
}
.palatino {
  font-family: palatino;
}

.secondary-color-section {
  border: 2px solid #babec5;
  padding: 20px;
  border-radius: 7px;

  legend {
    font-size: 16px;
    font-weight: 400;
    color: #393a3d;
  }
}

.monogram-tile {
  label {
    width: 100%;
  }

  label [class^='StyledTile__Icons'] {
    width: auto;
    height: auto;
  }

  input[aria-checked='true'] + label {
    border: 3px #108000 solid;
  }
}

.upload-logo-tile {
  img {
    width: fit-content !important;
  }

  label {
    width: 100%;
  }

  label [class^='StyledTile__Icons'] {
    width: auto;
    height: auto;
  }

  input[aria-checked='true'] + label {
    border: 3px #108000 solid;
  }

  div[class^='StyledTile__InfoContainer'] {
    p:first-of-type {
      font-size: 11px;
    }
    p {
      font-size: 10px;
    }
  }
}

.upload-image {
  svg {
    overflow: visible;
  }
}
.confirmation-modal {
  z-index: 20000 !important;
}
.confirmation-md-body p {
  font-size: 13px;
  color: #383a3d;
  line-height: 1.5em;
}
.confirmation-md-title {
  font-size: 15px;
  color: #383a3d;
  font-weight: 600;
}
.confirmation-md-bank {
  padding-left: 25px;
  font-size: 13px;
  margin-bottom: 12px;
}
.confirmation-md-bank span {
  font-weight: 600;
}
.confirmation-sub-title {
  font-size: 13px;
  color: #383a3d;
  font-weight: 600;
}
.confirmation-text {
  margin-bottom: 12px;
}
.confirmation-md-footer {
  display: inline-block;
}
.confirmation-md-footer button span span {
  font-size: 15px;
  padding: 8px 12px;
}
.hide-in-mobile {
  display: none !important;
}
.shadow-logo {
  opacity: 0.15;
}
.upgrade-step-sub-title {
  font-weight: 300;
  font-size: 24px;
  padding: 10px 75px 0;
  color: #393a3d;
}
.upgrade-step-title {
  font-size: 40px;
  color: #393a3d;
  font-weight: 300;
  text-align: center;
  padding: 0 15px;
}
.upgrade-cancel {
  cursor: pointer;
  font-size: 16px;
}
.upgrade-cancel span {
  font-weight: 500;
}
.justify-center {
  justify-content: center;
}
.checks-upgrade-btn-section {
  margin: 80px 34px 80px;
  text-align: center;
}
.upgrade-disclaimer-container {
  padding: 0 65px 20px;
}
.upgrade-disclaimer {
  font-size: 16px;
  color: #393a3d;
  font-weight: 300;
}
.customization-main-section-upgrade {
  margin: 80px 34px 60px;
  font-size: 44px;
  text-align: center;
}
.mob-features {
  display: none;
}
.dktp-features {
  display: block;
}
// Keep the media query at the end only
@media (min-width: 0) {
  .highlighted-wrapper {
    background-color: #f4f5f8;
    padding: 20px 20px 80px;
  }

  .highlighted-section {
    margin-left: 0;
    margin-right: 0;
  }

  .customization-main-section {
    margin: 60px 34px 0;
    text-align: center;
  }

  .step-title-section {
    font-size: 36px;
    margin-bottom: 60px;
  }

  .steps-section {
    margin-right: 0;
  }

  .cust-image-col {
    display: none;
  }

  .mobile-preview-line {
    display: flex;
    // margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }

  .cust-upload-wrapper {
    width: inherit;
    margin: 20px;
  }

  .logo-add-button {
    width: 100%;
    margin-bottom: 20px;
  }

  .logo-edit-button {
    width: 100%;
    margin-bottom: 10px !important;
    margin-right: 0;
  }

  .logo-remove-button {
    width: 100%;
    margin-bottom: 0;
  }
}

@media (min-width: 480px) {
  .highlighted-wrapper {
    background-color: #f4f5f8;
    padding: 20px 20px 80px;
  }

  .highlighted-section {
    margin-left: 0;
    margin-right: 0;
  }

  .customization-main-section {
    margin: 60px 34px 0;
    font-size: 44px;
    text-align: center;
  }

  .step-title-section {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .steps-section {
    margin-right: 40px;
  }

  .step-title::before {
    content: '\a';
    white-space: pre;
  }

  .cust-image-col {
    display: inherit;
  }

  .image-component-section {
    display: inherit;
    font-family: Helvetica, sans-serif !important;
  }

  .mobile-preview-line {
    display: none;
  }

  .cust-upload-wrapper {
    width: 500px !important;
    margin: 20px;
  }

  .logo-add-button {
    width: 100%;
    margin-bottom: 20px;
  }

  .logo-edit-button {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .logo-remove-button {
    width: 100%;
    margin-bottom: 0;
  }
  .reorder-customization-container {
    margin-bottom: 20px;
  }
  .order-upgrade-btn-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-upgrade-btn-2 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .highlighted-wrapper-upgrade {
    background-color: #f6f7f9;
    padding: 72px 70px 57px 70px;
  }
}

@media (min-width: 769px) {
  .highlighted-wrapper {
    background-color: #f6f7f9;
    padding: 80px 70px;
  }

  .highlighted-section {
    margin-left: 70px;
    margin-right: 70px;
  }

  .customization-main-section {
    margin: 80px 34px 60px;
    font-size: 44px;
    text-align: center;
  }

  .step-title-section {
    font-size: 36px;
    margin-bottom: 60px;
  }

  .steps-section {
    margin-right: 60px;
  }

  .step-title::before {
    content: '';
  }

  .image-component-section {
    display: inherit;
    font-family: Helvetica, sans-serif !important;
  }

  .cust-image-col {
    display: inherit;
  }

  .mobile-preview-line {
    display: none;
  }

  .cust-upload-wrapper {
    width: 500px !important;
    margin: 40px;
  }

  .logo-add-button {
    width: 100%;
    margin-bottom: 20px;
  }

  .logo-edit-button {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .logo-remove-button {
    width: 100%;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .logo-add-button {
    width: initial;
    margin-bottom: 0;
  }

  .logo-edit-button {
    width: initial;
    margin-bottom: 0;
    margin-right: 10px !important;
  }

  .logo-remove-button {
    width: initial;
    margin-bottom: 0;
  }
}
.cust-color-picker {
  display: block !important;
}
.color-toggler {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.account-open-dropdown {
  display: inline-block;
}
.margin-account {
  margin-right: 20px;
}

.customization-quantity-container {
  .add-security-env {
    font-size: 16px;
    color: #393a3d;
    font-weight: 600;
  }
  .width-reverse-tag p {
    color: #393a3d;
    margin-bottom: 17px;
  }
}

.image-preview-modal-content {
  width: 100%;
  overflow: auto !important;
  position: relative;
}
.image-preview-modal-scroll .cWXDlr {
  max-height: 500px;
}

.custom-preview-header-modal {
  font-size: 38px;
  line-height: 40px;
  color: #393a3d;
  font-weight: 600;
  margin-bottom: 5px;
}
.custom-preview-sub-header-modal {
  font-size: 16px;
  font-weight: 600;
  color: #404040;
  line-height: 30px;
}
//Tablet only
@media screen and (max-width: 768px) {
  .customization-main-section-upgrade {
    margin: 50px 22px 0;
  }
  .upgrade-step-sub-title {
    padding: 10px 0px 0;
  }
  .highlighted-wrapper-upgrade {
    padding: 12px 20px 27px;
    background-color: #f4f5f8;
  }
  .checks-upgrade-btn-section {
    margin: 60px 34px 80px;
  }
}
@media only screen and (max-width: 479px) {
  .image-preview-modal-scroll {
    .bKkkl {
      max-height: none !important;
    }
  }
  .reorder-desp-list {
    margin-top: 20px;
  }
  .reorder-customization-container {
    margin-bottom: 20px;
  }
  .upgrade-step-sub-title {
    padding: 10px 0px 0;
  }
  .upgrade-step-title {
    padding: 0px;
  }
  .checks-upgrade-btn-section {
    margin: 60px 0px 64px;
    text-align: center;
  }
  .order-upgrade-btn-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-upgrade-btn-2 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .customization-main-section-upgrade {
    margin: 50px 22px 0;
  }
  .highlighted-wrapper-upgrade {
    padding: 12px 20px 33px;
    background-color: #f4f5f8;
  }
  .mobile-margin-btm {
    margin-bottom: 10px;
  }
  .mob-security-features {
    font-weight: 300;
    font-size: 24px;
    padding: 7px 5px;
    color: #393a3d;
  }
  .mob-security-features:nth-child(2) {
    margin-top: 25px;
  }
  .mob-features {
    display: block;
  }
  .dktp-features {
    display: none;
  }
  .step-title-section {
    margin-bottom: 40px;
  }
  .upgrade-disclaimer-container {
    padding: 0 20px 20px;
  }
}
.tooltip-container-customization{
  margin:4px;
  .tooltip-header{
    font-size: 13px;
    font-weight:700;
  }
  .tooltip-body{
    font-size: 13px;
    font-weight:500;
  }
  .tooltip-img{
    margin-top:20px;
  }
  .tooltip-top-img{
    margin-bottom:20px;
  }
}
.ofs-message {
  font-size: 19px;
  font-weight: 400;
  color: #8D9096;
  margin: 20px 0 0 0;
}
