// Keep the media query at the end only
.direct-deposit-forms-image-container {
  position: relative;
  text-align: center;
  .image-company-info {
      position: absolute;
      font-size: 9px;
      height: 19px;
      width:35%;
    }
    .direct-deposit-forms-logo {
      position: absolute;
      top: 5%;
      left: 4%;

      img {
        width: 50px;
        height: 50px;
      }
    }
    .image-summary-text {
      position: absolute;
      font-size: 100%;
      width: 85%;
      top: 35%;
      left: 5%;
      text-align: left;
    }
    .image-checkStartingNumber-text {
      position: absolute;
      font-size: 100%;
      width: 15%;
      top: 3%;
      left: 80%;
      text-align: right;
    }
}

.direct-deposit-forms-modal-image-container {
  .image-company-info {
    position: absolute;
    font-size: 9px;
    width: 35%;
    line-height: 12px;
    text-align: center;
    top: 10%;
    left: 18%;
  }
  .direct-deposit-forms-logo {
    position: absolute;
    top: 10%;
    left: 10%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .image-summary-text {
    position: absolute;
    font-size: 11px;
    width: 85%;
    top: 32%;
    left: 6%;
    text-align: left;
  }
  .image-checkStartingNumber-text {
      position: absolute;
      font-size: 11px;
      width: 15%;
      top: 3%;
      left: 75%;
      text-align: right;
    }
}

@media (min-width: 0) {
  .direct-deposit-forms-modal-image-container {
    .image-company-info {
    }
    .logo {
      position: absolute;
      left: 8%;
      top: 13%;
      width: 45px;
    }
    .shadow-logo {
      position: absolute;
      left: 28%;
      top: 43%;
      width: 150px;
    }
  }
}

@media (min-width: 480px) {
  .direct-deposit-forms-image-container {
    .image-company-info {
      top: 20%;
      left: 20%;
    }

    .shadow-logo {
      position: absolute;
      left: 28%;
      top: 43%;
      width: 150px;
    }
  }

  .direct-deposit-forms-modal-image-container {
    .image-company-info {
    }
    .shadow-logo {
      position: absolute;
      left: 30%;
      top: 49%;
      width: initial;
    }
  }
}

@media (min-width: 769px) {
  .direct-deposit-forms-image-container {
    .image-company-info {
  		top: 3%;
    	left: 22%;
    }
    .logo {
        position: absolute;
        left: 4%;
        top: 3%;
        flex-basis: 0;
        flex-grow: 1;
        width: auto;
        height: 40px;
    }
    .shadow-logo {
      position: absolute;
      left: 27%;
      top: 10%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
      height: 100px;
    }
    .shadow-logo-voucher {
      position: absolute;
      left: 27%;
      top: 40%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
      height: 100px;
    }
  }

  .direct-deposit-forms-modal-image-container {
    .image-company-info {
    	top: 3%;
  		left: 21%;
    }
    .logo{
      position: absolute;
      left: 6%;
      top: 3%;
      width: auto;
      height: 45px;
    }
    .shadow-logo {
      position: absolute;
      left: 35%;
      top: 10%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
      height: 100px;
    }
    .shadow-logo-voucher {
      position: absolute;
      left: 35%;
      top: 40%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
      height: 100px;

    }
  }
}
