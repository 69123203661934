// Keep the media query at the end only
.manual-checks-image-container {
  position: relative;
  text-align: center;
  .image-company-info {
    position: absolute;
    font-size: 9px;
    height: 19px;
    width: 25%;
  }
  .logo {
    position: absolute;
    top: 2%;
    left: 4%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .sign-lines {
    line-height: 16px;
    p {
      font-size: 10px;
    }
  }
  .sign-lines-upper-text {
    top: 20%;
    position: absolute;
    right: 10%;
  }

  .sign-lines-lower-text {
    top: 23%;
    position: absolute;
    right: 10%;
  }

  .sign-upper-line {
    position: absolute;
    top: 21.5%;
    width: 30%;
    left: 67%;
  }
  .image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 35%;
    left: 5%;
    text-align: left;
  }
  .lower-image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 65%;
    left: 5%;
    text-align: left;
  }
  .image-checkStartingNumber-text {
    position: absolute;
    font-size: 10px;
    width: 15%;
    top: 3%;
    left: 80%;
    text-align: right;
  }
  .image-micr {
    position: absolute;
    top: 28.7%;
    left: 41%;
    font-size: 7px;

    img {
      width: 7px;
      height: 9px;
    }
  }
  .image-bank-info {
    position: absolute;
    top: 19.3%;
    left: 30%;
    font-size: 6.5px;
    line-height: 8px;
  }
  .check-bottom-section {
    .align-info-bottom {
      top: 68.4%;
    }
    .align-info-basic-bottom {
      top: 54%;
    }
    .align-info-bottom-bank {
      top: 85.5%;
    }
    .align-micr-bottom {
      top: 95.1%;
    }
    .align-sign-bottom {
      top: 88.5%;
    }
    .align-sign-bottom-uppertext {
      top: 86.5%;
    }
    .align-sign-bottom-line {
      top: 88.5%;
    }
    .align-sign-bottom-lowertext {
      top: 90%;
    }
  }
  .check-middle-section {
    .align-info-middle {
      top: 35.5%;
    }
    .align-info-middle-bank {
      top: 52.5%;
    }
    .align-micr-middle {
      top: 62%;
    }
    .align-sign-middle {
      top: 55%;
    }
    .align-sign-middle-uppertext {
      top: 53%;
    }
    .align-sign-middle-line {
      top: 55%;
    }
    .align-sign-middle-lowertext {
      top: 56.5%;
    }
  }
}

.manual-checks-modal-image-container {
  .image-company-info {
    position: absolute;
    font-size: 9px;
    width: 30%;
    line-height: 15px;
    text-align: center;
    top: 4%;
    left: 18%;
  }
  .logo {
    position: absolute;
    top: 5%;
    left: 5%;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .sign-lines {
    line-height: 16px;
    p {
      font-size: 12px;
    }
  }
  .sign-lines-upper-text {
    top: 20.5%;
    position: absolute;
    right: 17%;
    right: 12%;
  }
  .sign-lines-lower-text {
    top: 23.5%;
    position: absolute;
    right: 12%;
  }
  .sign-upper-line {
    position: absolute;
    top: 21.5%;
    width: 29%;
    left: 68%;
  }
  .image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 35%;
    left: 10%;
    text-align: left;
  }
  .lower-image-summary-text {
    position: absolute;
    font-size: 100%;
    width: 85%;
    top: 65%;
    left: 10%;
    text-align: left;
  }
  .image-checkStartingNumber-text {
    position: absolute;
    font-size: 10px;
    width: 15%;
    top: 3%;
    left: 78%;
    text-align: right;
  }
  .image-micr {
    position: absolute;
    top: 29%;
    left: 25%;
    font-size: 11px;

    img {
      width: 6px;
      height: 9px;
    }
  }
  .image-bank-info {
    position: absolute;
    top: 5%;
    left: 50%;
    font-size: 8px;
    line-height: 9px;
  }
  .check-bottom-section {
    .align-info-bottom {
      top: 69.5%;
    }
    .align-info-basic-bottom {
      top: 53.4%;
    }
    .align-info-bottom-bank {
      top: 86%;
    }
    .align-micr-bottom {
      top: 94%;
    }
    .align-sign-bottom {
      top: 82%;
    }
    .align-sign-bottom-uppertext {
      top: 86%;
    }
    .align-sign-bottom-line {
      top: 87.5%;
    }
    .align-sign-bottom-lowertext {
      top: 90%;
    }
  }
  .check-middle-section {
    .align-info-middle {
      top: 36.5%;
    }
    .align-info-middle-bank {
      top: 52.5%;
    }
    .align-micr-middle {
      top: 61%;
    }
    .align-sign-middle {
      top: 52%;
    }
    .align-sign-middle-uppertext {
      top: 53%;
    }
    .align-sign-middle-line {
      top: 54.5%;
    }
    .align-sign-middle-lowertext {
      top: 57%;
    }
  }
}

@media (min-width: 0) {
  .manual-checks-modal-image-container {
    .image-company-info {
    }
    .logo {
      position: absolute;
      left: 5%;
      top: 5%;
      max-height: 60px;
      max-width: 60px;
    }
    .shadow-logo {
      position: absolute;
      left: 28%;
      top: 43%;
      width: 150px;
    }
    .image-micr {
      position: absolute;
      top: 60%;
      left: 25%;
      font-size: 7px;

      img {
        width: 7px;
        height: 9px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 2%;
      left: 54%;
      line-height: 10px;
      font-size: 8px;
    }
  }
}

@media (min-width: 480px) {
  .manual-checks-image-container {
    .image-company-info {
      top: 20%;
      left: 20%;
    }

    .shadow-logo {
      position: absolute;
      left: 28%;
      top: 43%;
      width: 150px;
    }
  }

  .manual-checks-modal-image-container {
    .image-company-info {
    }
    .shadow-logo {
      position: absolute;
      left: 30%;
      top: 49%;
      width: initial;
    }
  }

  .image-micr {
    position: absolute;
    top: 29%;
    left: 25%;
    font-size: 11px;

    img {
      width: 8px;
      height: 9px;
    }
  }

  .image-bank-info {
    position: absolute;
    top: 4%;
    left: 54%;
    font-size: 10px;
  }
}

@media (min-width: 769px) {
  .manual-checks-image-container {
    .image-company-info {
      top: 2%;
      left: 36%;
      line-height: 10px;
    }
    .image-company-info > div {
      font-size: 7.5px !important;
    }
    .logo {
      position: absolute;
      left: 27.5%;
      top: 3%;
      flex-basis: 0;
      flex-grow: 1;
      max-height: 35px;
      max-width: 35px;
    }
    .shadow-logo {
      position: absolute;
      left: 30%;
      top: 9%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
      opacity: 0.15;
    }
  }

  .manual-checks-modal-image-container {
    .image-company-info {
      top: 3%;
      left: 36%;
      line-height: 12px;
    }
    .image-company-info > div {
      font-size: 7.5px !important;
    }
    .logo {
      position: absolute;
      left: 28%;
      top: 3%;
      max-height: 45px;
      max-width: 45px;
    }
    .shadow-logo {
      position: absolute;
      left: 30%;
      top: 11%;
      flex-basis: 0;
      flex-grow: 1;
      height: 100px;
      width: auto;
      opacity: 0.15;
    }
    .image-micr {
      position: absolute;
      top: 28%;
      left: 38%;
      font-size: 11px;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .image-bank-info {
      position: absolute;
      top: 20%;
      left: 30%;
      font-size: 7.5px;
    }
  }
}

.preview-modal-content {
  .manual-checks-image-container {
    .image-micr {
      top: 28%;
    }
    .sign-upper-line{
      top:21%
    }
    .check-middle-section {
      .align-micr-middle {
        top: 61%;
      }
      .align-sign-middle-line {
        top: 54%;
      }
    }
    .check-bottom-section {
      .align-micr-bottom {
        top: 94%;
      }
      .align-sign-bottom-line {
        top: 87%;
      }
      .align-sign-bottom-lowertext{
        top:89.5%
      }
    }
  }
}
