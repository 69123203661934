.product-invoice-image-container {
  position: relative;
  text-align: center;
}

@media (min-width: 0) {
  .product-invoice-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 10%;
      left: 29%;
      font-size: 11px;
      line-height: 11px;
    }

    .logo {
      position: absolute;
      left: 8%;
      top: 13%;
      width: 45px;
    }

    .shadow-logo {
      position: absolute;
      left: 28%;
      top: 95%;
      width: 133px;
    }
  }
}

@media (min-width: 480px) {
  .product-invoice-image-container {
    .image-company-info {
      position: absolute;
      top: 4%;
      left: 27%;
      font-size: 11px;
      line-height: 11px;
    }

    .logo {
      position: absolute;
      left: 8%;
      top: 6%;
      width: 50px;
    }

    .shadow-logo {
      position: absolute;
      left: 28%;
      top: 43%;
      width: 150px;
    }
  }

  .product-invoice-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 59px;
      left: 29%;
      font-size: 11px;
      line-height: 13px;
    }

    .logo {
      position: absolute;
      left: 11%;
      top: 7%;
      width: initial;
    }

    .shadow-logo {
      position: absolute;
      left: 30%;
      top: 49%;
      width: initial;
    }
  }
}

@media (min-width: 769px) {
  // Printable Deposit slips - DEP=02
  .customer-statement-image-container {
    .image-company-info {
      position: absolute;
      top: 37px;
      left: 29%;
      font-size: 11px;
    }

    .logo {
      position: absolute;
      left: 5%;
      top: 5%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
      max-height: 50px;
    }

    .shadow-logo {
      position: absolute;
      left: 15%;
      top: 52%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
    }
  }

  .customer-statement-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 7%;
      left: 29%;
      font-size: 11px;
      line-height: 13px;
    }

    .logo {
      position: absolute;
      left: 10%;
      top: 7%;
      width: initial;
      max-height: 50px;
    }

    .shadow-logo {
      position: absolute;
      left: 25%;
      top: 55%;
      width: initial;
    }
  }

  .product-invoice-image-container {
    .image-company-info {
      position: absolute;
      top: 37px;
      left: 29%;
      font-size: 11px;
    }

    .logo {
      position: absolute;
      left: 8%;
      top: 5%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
      height: 46px;
    }

    .shadow-logo {
      position: absolute;
      left: 27%;
      top: 46%;
      flex-basis: 0;
      flex-grow: 1;
      width: auto;
    }
  }

  .product-invoice-modal-image-container {
    .image-company-info {
      position: absolute;
      top: 7%;
      left: 29%;
      font-size: 11px;
      line-height: 13px;
    }

    .logo {
      position: absolute;
      left: 10%;
      top: 7%;
      width: initial;
      height: 46px;
    }

    .shadow-logo {
      position: absolute;
      left: 34%;
      top: 47%;
      width: initial;
    }
  }
}
