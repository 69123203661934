.row {
  margin-right: 0;
  margin-left: 0;
}

@media (min-width: 0) {
  .col-xs {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    min-height: 1px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-xs-1 {
    flex: 0 0 calc(8.33333%);
    max-width: calc(8.33333%);
  }
  .col-xs-2 {
    flex: 0 0 calc(16.66667%);
    max-width: calc(16.66667%);
  }
  .col-xs-3 {
    flex: 0 0 calc(25%);
    max-width: calc(25%);
  }
  .col-xs-4 {
    flex: 0 0 calc(33.33333%);
    max-width: calc(33.33333%);
  }
  .col-xs-5 {
    flex: 0 0 calc(41.66667%);
    max-width: calc(41.66667%);
  }
  .col-xs-6 {
    flex: 0 0 calc(50%);
    max-width: calc(50%);
  }
  .col-xs-7 {
    flex: 0 0 calc(58.33333%);
    max-width: calc(58.33333%);
  }
  .col-xs-8 {
    flex: 0 0 calc(66.66667%);
    max-width: calc(66.66667%);
  }
  .col-xs-9 {
    flex: 0 0 calc(75%);
    max-width: calc(75%);
  }
  .col-xs-10 {
    flex: 0 0 calc(83.33333%);
    max-width: calc(83.33333%);
  }
  .col-xs-11 {
    flex: 0 0 calc(91.66667%);
    max-width: calc(91.66667%);
  }
  .col-xs-12 {
    flex: 0 0 calc(100%);
    max-width: calc(100%);
  }
}

@media (min-width: 480px) {
  .col-sm {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    min-height: 1px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-sm-1 {
    flex: 0 0 calc(8.33333%);
    max-width: calc(8.33333%);
  }
  .col-sm-2 {
    flex: 0 0 calc(16.66667%);
    max-width: calc(16.66667%);
  }
  .col-sm-3 {
    flex: 0 0 calc(25%);
    max-width: calc(25%);
  }
  .col-sm-4 {
    flex: 0 0 calc(33.33333%);
    max-width: calc(33.33333%);
  }
  .col-sm-5 {
    flex: 0 0 calc(41.66667%);
    max-width: calc(41.66667%);
  }
  .col-sm-6 {
    flex: 0 0 calc(50%);
    max-width: calc(50%);
  }
  .col-sm-7 {
    flex: 0 0 calc(58.33333%);
    max-width: calc(58.33333%);
  }
  .col-sm-8 {
    flex: 0 0 calc(66.66667%);
    max-width: calc(66.66667%);
  }
  .col-sm-9 {
    flex: 0 0 calc(75%);
    max-width: calc(75%);
  }
  .col-sm-10 {
    flex: 0 0 calc(83.33333%);
    max-width: calc(83.33333%);
  }
  .col-sm-11 {
    flex: 0 0 calc(91.66667%);
    max-width: calc(91.66667%);
  }
  .col-sm-12 {
    flex: 0 0 calc(100%);
    max-width: calc(100%);
  }
}

@media print {
  .col-md {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    min-height: 1px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-md-1 {
    flex: 0 0 calc(8.33333%);
    max-width: calc(8.33333%);
  }
  .col-md-2 {
    flex: 0 0 calc(16.66667%);
    max-width: calc(16.66667%);
  }
  .col-md-3 {
    flex: 0 0 calc(25%);
    max-width: calc(25%);
  }
  .col-md-4 {
    flex: 0 0 calc(33.33333%);
    max-width: calc(33.33333%);
  }
  .col-md-5 {
    flex: 0 0 calc(41.66667%);
    max-width: calc(41.66667%);
  }
  .col-md-6 {
    flex: 0 0 calc(50%);
    max-width: calc(50%);
  }
  .col-md-7 {
    flex: 0 0 calc(58.33333%);
    max-width: calc(58.33333%);
  }
  .col-md-8 {
    flex: 0 0 calc(66.66667%);
    max-width: calc(66.66667%);
  }
  .col-md-9 {
    flex: 0 0 calc(75%);
    max-width: calc(75%);
  }
  .col-md-10 {
    flex: 0 0 calc(83.33333%);
    max-width: calc(83.33333%);
  }
  .col-md-11 {
    flex: 0 0 calc(91.66667%);
    max-width: calc(91.66667%);
  }
  .col-md-12 {
    flex: 0 0 calc(100%);
    max-width: calc(100%);
  }

  .col-print-hide {
    display: none;
  }
}

@media (min-width: 769px) {
  .col-lg {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    min-height: 1px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-lg-1 {
    flex: 0 0 calc(8.33333%);
    max-width: calc(8.33333%);
  }
  .col-lg-2 {
    flex: 0 0 calc(16.66667%);
    max-width: calc(16.66667%);
  }
  .col-lg-3 {
    flex: 0 0 calc(25%);
    max-width: calc(25%);
  }
  .col-lg-4 {
    flex: 0 0 calc(33.33333%);
    max-width: calc(33.33333%);
  }
  .col-lg-5 {
    flex: 0 0 calc(41.66667%);
    max-width: calc(41.66667%);
  }
  .col-lg-6 {
    flex: 0 0 calc(50%);
    max-width: calc(50%);
  }
  .col-lg-7 {
    flex: 0 0 calc(58.33333%);
    max-width: calc(58.33333%);
  }
  .col-lg-8 {
    flex: 0 0 calc(66.66667%);
    max-width: calc(66.66667%);
  }
  .col-lg-9 {
    flex: 0 0 calc(75%);
    max-width: calc(75%);
  }
  .col-lg-10 {
    flex: 0 0 calc(83.33333%);
    max-width: calc(83.33333%);
  }
  .col-lg-11 {
    flex: 0 0 calc(91.66667%);
    max-width: calc(91.66667%);
  }
  .col-lg-12 {
    flex: 0 0 calc(100%);
    max-width: calc(100%);
  }
}
