// Keep the media query at the end only
.business-envelopes-image-container {
  position: relative;
  text-align: center;
  .image-company-info {
      position: absolute;
      font-size: 9px;
      line-height: 100%;
      text-align: center;
      width:50%;
    }
}

.business-envelopes-modal-image-container {
  .image-company-info {
    position: absolute;
    font-size: 9px;
    width: 50%;
    line-height: 100%;
    text-align: center;
  }
}
@media (min-width: 0) {
  .business-envelopes-modal-image-container {
    .image-company-info {
      top: 70px;
      left: 120px;
    }
  }
}

@media (min-width: 480px) {
  .business-envelopes-image-container {
    .image-company-info {
      top: 60%;
      left: 50%;
    }
  }

  .business-envelopes-modal-image-container {
    .image-company-info {
      
    }
  }
}

@media (min-width: 769px) {
  .business-envelopes-image-container {
    .image-company-info {
      top: 60%;
      left: 40%;
    }
  }

  .business-envelopes-modal-image-container {
    .image-company-info {
      top: 60%;
      left: 40%;
    }
  }
}
