@import '../stylesheets/button.scss';
.template {
  text-align: center;
  color: #0d333f;
  // font-family: Avenir Next LT Pro;
  .paddingOutside {
    padding-left: 80px;
    padding-top: 0px;
    padding-right: 80px;
    .back-to-center {
      margin: 80px 0px 80px 0px;
      $button-min-height: 48px;
      @include secondary-dark-blue-button($button-min-height);
    }
    .back-to-center > span > span {
      color: unset;
    }
  }
}
h1 {
  font-size: 44px;
  font-weight: bold;
}
.subHeader {
  margin-bottom: 80px;
  font-size: 24px;
}

.details {
  text-align: start;
  padding-right: 40px;
}
.width-295px {
  width: 295px;
}

.ResourceCenterDetails {
  align-items: flex-start;
  margin-bottom: 60px;
}

.smallTitle {
  font-size: 16px;
  color: #0a8543;
  font-weight: 700;
}
.heading {
  font-size: 26px;
  font-weight: bold;
}
.Faqheading {
  font-size: 24px;
  font-weight: bold;
}
$fontSize: 15px, 16px, 19px, 20px, 22px, 34px;
@each $font in $fontSize {
  .font-#{$font}{
    font-size: $font;
  }
}
.font-19-8 {
  font-size: 19.8px;
}
.font-weigth-medium {
  font-weight: 500;
}
.font-weight-800 {
  font-weight: 800;
}
.details > a {
  text-decoration: none;
  color: #0077c5;
}
.paddingOutsideFaq {
  padding: 60px 80px 80px 80px;
}

.faq {
  align-items: flex-start;
}
.faq-background-color {
  background-color: #f3f2ef;
}

.bottomBannar {
  background-color: #4ebf6a;
  text-align: center;
  .bottomBannarHeading {
    padding-top: 60px;
    font-size: 28px;
    font-weight: 800;
    color: #0d333f;
  }
  p {
    margin-bottom: 20px;
    font-size: 24px;
    color: #ffffff;
    font-weight: 500;
  }
  Button {
    margin-bottom: 40px;
  }
  .view-all-product-btn {
    $button-min-height: 48px;
    @include primary-dark-blue-button($button-min-height);
  }
}
.resource-center-article-btn{
  .margin-bottom-60px{
    margin-bottom: 60px;
  }
  .margin-bottom-80px{
    margin-bottom: 80px;
  }
}
.font-italic {
  font-style: italic;
}

.list-style-none {
  list-style: none;
}
.list-span{
  list-style-type: none;
  font-weight: 600;
}
.line-heigth-3 {
  line-height: 3;
}
.display-flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.supplies-resource-center {
  align-items: center;
  color: #0d333f;
  .top-h1 {
    margin-top: 13px;
    font-size: 44px;
  }
  .top-p {
    font-size: 24px;
    margin-bottom: 65px;
  }
  .center-nav-bar {
    display: flex;
    justify-content: center;
    margin-bottom: -60px;
  }

  .center-nav-bar >div >ul> li {
    display: contents;
  }
  .tab-header {
    font-weight: 500;
    color: #6b6c72;
  }
  .tab-header.active {
    font-weight: 600;
    font-family: 'AvenirNextDemi', 'HelveticaNeue', 'Helvetica', 'Arial', sans-serif;
    border-bottom: 4px solid #2ca01c;
    display: block;
    color: #0d333f;
  }
  .support-link {
    color: #0077c5;
    font-size: 17px;
    text-decoration: none;
    line-height: 2;
    font-weight: 600;
  }
  .support-section {
    padding-left: 135px;
    margin-top: 53px;
    margin-bottom: 80px;
    p {
      font-size: 40px;
      margin-bottom: 32px;
      font-weight: 600;
    }
  }
  .tab-header:hover {
    font-family: 'AvenirNextDemi', 'HelveticaNeue', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    color: #0d333f;
  }
  .hero-ariticle-resource-center {
    padding-left: 80px;
    .article-heading {
      display: flex;
      align-items: center;
      padding-left: 40px;
      img {
        padding-right: 20px;
        width: 35px;
        height: 35px;
      }
    }
    .article-shadow {
      background-color: white;
      margin-right: 80px;
      width: 85%;
      padding-left: 135px;
      padding-top: 80px;
      padding-bottom: 80px;
      box-shadow: 6px 6px 8px 0px #d7d5cec2;
      img {
        height: 165px;
        padding-left: 35px;
      }
    }
    .article-faq {
      padding: 60px 0px;
      padding-left: 65px;
    }

    .see-all-btn {
      $button-min-height: 48px;
      margin-left: 65px;
      @include primary-dark-blue-button($button-min-height);
    }
  }
  .true-background {
    background-color: #f3f2ef;
  }
}

.supplies-resource-support {
  .back-to-center {
    margin: 20px 0px 80px 0px;
    $button-min-height: 48px;
    @include secondary-dark-blue-button($button-min-height);
  }
  .back-to-center > span > span {
    color: unset;
  }
  .backToCheck {
    padding-left: 40px;
    font-size: 16px;
    line-height: 1.6;
    color: #0077c5;
    text-decoration: none;
    font-weight: 500;
  }
  .padding-side-160 {
    padding: 0px 160px;
  }
  .padding-side-50 {
    padding: 0px 50px;
  }
  .smallHeading {
    font-size: 19px;
    color: #0a8543;
    font-weight: 700;
  }
  .largeHeading {
    font-size: 44px;
    color: #0d333f;
    font-weight: 700;
  }
  .para {
    font-size: 20px;
    font-weight: 400;
    color: #0d333f;
    padding-bottom: 23px;
    text-align: justify;
    a {
      text-decoration: none;
      color: #0077c5;
      font-weight: 600;
    }
  }
  .para ul > li {
    padding-left: 10px;
  }
  .font-size-20-regular {
    font-size: 20px ;
    font-weight: 400;
  }
  .image{
    padding-top: 6px;
    margin-bottom: 50px;
    text-align: center;
  }
  .heading-width {
    width: max-content;
    font-size: 34px;
    font-weight: 700;
  }
  .headingSubContent {
    font-size: 20px;
    font-weight: 400;
    color: #0d333f;
    padding-bottom: 23px;
    text-align: justify;
    a {
      text-decoration: none;
      color: #0077c5;
      font-weight: 600;
    }
  }
}
.font-size-12{
  font-size: 12px;
}

.help-article {
  color: #0d333f;
  h1 {
    font-size: 44px;
    font-weight: 700;
  }
  h2 {
    font-size: 34px;
    font-weight: 700;
  }
  p {
    font-size: 20px;
    font-weight: 400;
  }
  a {
    text-decoration: none;
    color: #0077c5;
  }
}

.StickyBar {
  z-index: 999;
  background-color: #fff;
  position: fixed;
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  box-shadow: -1px 7px 12px 0 rgba(0,0,0,.08);
  transition: top 0.4s;
}
.top-60px {
  top: 60px;
}
.top-120px {
  top: 120px;
}
.top-180px {
  top: 180px;
}
.z-index-999 {
  z-index: 999;
}
.z-index-997 {
  z-index: 997;
}
.StickyBar > div> ul >li{
  display: contents;
}