@mixin primary-green-button($button-min-height){
    background-color: #0A8543;
    color: #FFF;
    border: 1px solid #0A8543;
    min-height: $button-min-height;
    &:hover{
      background-color: #086D37;
      color: #FFF;
      border-radius: 4px;
      span {
        background-color: #086D37;
        color: #FFF;
        border-radius: 4px;
      }
    }
}

@mixin primary-dark-blue-button($button-min-height){
  background-color: #0D333F !important;
  color: #FFF;
  border: 1px solid #0d333f !important;
  min-height: $button-min-height !important;
  &:hover{
    background-color: #09222A !important;
    color: #FFF;
    border-radius: 4px;
    span {
      background-color: #09222A;
      color: #FFF;
      border-radius: 4px;
    }
  }
}

@mixin secondary-dark-blue-button($button-min-height){
  background-color: #FFF;
  color: #0D333F;
  border: 1px solid #0d333f;
  min-height: $button-min-height;
  &:hover{
    background-color: #0D333F;
    color: #FFF;
    border-radius: 4px;
    span {
      background-color: #0D333F;
      color: #FFF;
      border-radius: 4px;
    }
  }
}